import styled from "styled-components";

const asNumber = (size: string): number => Number(size.replace("px", ""));

export const MaxWidthContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing.baseSpacing};
  padding-right: ${({ theme }) => theme.spacing.baseSpacing};

  ${({ theme }) => theme.mediaQueries.md} {
    width: ${({ theme }) => `${asNumber(theme.breakpoints[1]) - asNumber(theme.spacing.threeQuarterSpacing)}px`};
    padding-left: 0;
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: ${({ theme }) => `${asNumber(theme.breakpoints[2]) - asNumber(theme.spacing.doubleSpacing)}px`};
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: ${({ theme }) => theme.page.maxWidth};
  }
`;
