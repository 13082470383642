import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { Button, Heading, Alert } from "@puregym/ui";
import { ModalContent, BookingAction, BookingResult } from "./shared";
import { BookingError as ApiBookingError, ScheduledClass } from "src/bookingsApi/models";
import { useLocale } from "src/providers/LocalizationProvider";
import { TimetableType } from "src/models/timetableType";
import { replaceTokens } from "src/utils/stringUtils";
import { BookingErrors } from "./BookingErrors";

type HeadingProps = React.ComponentProps<typeof Heading>;

const SubHeading = styled(Heading)<HeadingProps>`
  color: ${({ theme }) => theme.colors.text};
`;

const BackButton = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.quarterSpacing} ${theme.spacing.baseSpacing}`};

  border-radius: ${({ theme }) => theme.radii.double};
  border: 1px solid ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.text};

  line-height: ${({ theme }) => theme.lineHeights.body};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  &:not(:disabled):hover,
  &:not(:disabled):focus:hover {
    border-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.opacities.darkSubtle};
  }

  &:not(:disabled):focus {
    border-color: none;
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

const StatusMessage = styled(Alert)`
  font-size: ${({ theme }) => theme.fontSizes.standard};
  margin-bottom: ${({ theme }) => theme.spacing.baseSpacing};
  padding-top: ${({ theme }) => theme.spacing.baseSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.baseSpacing};
`;

type Messages = {
  successMessage: string;
  errorMessage: string;
};

type BookingActionMessages = Record<BookingAction, Messages>;

export type Translations = {
  bookingModal: {
    backButtonText: string;
    gymAccessSlot: {
      name: string;
    };
    scheduledClass: {
      name: string;
    };
  } & BookingActionMessages;
};

type BookingConfirmationProps = {
  renderClassDetails: () => ReactNode | ReactNode[];
  scheduledClass: ScheduledClass;
  onBackButtonClick: MouseEventHandler<HTMLButtonElement>;
  bookingAction: BookingAction;
  bookingResult: BookingResult;
  timetableType: TimetableType;
  bookingErrors: ApiBookingError[];
};

export const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  scheduledClass,
  renderClassDetails,
  onBackButtonClick,
  bookingAction,
  bookingResult,
  timetableType,
  bookingErrors = [],
}: BookingConfirmationProps) => {
  const { getLabels } = useLocale();
  const { bookingModal: labels } = getLabels<Translations>();

  const getStatusMessage = () => {
    const messages: Messages = labels[bookingAction];
    const statusMessageFormat = messages[`${bookingResult}Message` as keyof Messages];
    const statusMessage = replaceTokens(statusMessageFormat, { bookingType: labels[timetableType].name });
    return statusMessage;
  };

  const renderStatusMessage = () => {
    const statusMessage = getStatusMessage();

    if (bookingResult === BookingResult.Failure) {
      return <BookingErrors bookingErrors={bookingErrors} statusMessage={getStatusMessage()} />;
    }

    return (
      <StatusMessage variant="success" role="status">
        {statusMessage}
      </StatusMessage>
    );
  };

  return (
    <ModalContent>
      <SubHeading as="h2" variant="gamma">
        {scheduledClass.name}
      </SubHeading>

      {renderStatusMessage()}

      {renderClassDetails()}

      <BackButton variant="plain" onClick={onBackButtonClick}>
        {labels.backButtonText}
      </BackButton>
    </ModalContent>
  );
};
