import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useTheme } from "styled-components";
import { Modal } from "@puregym/ui";
import { BookingId, EntityId, ScheduledClass, isOnWaitingList, ApiError, BookingError } from "src/bookingsApi/models";
import { ClassDetails } from "./components/ClassDetails";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import ReactModal from "react-modal";
import { useLocale } from "src/providers/LocalizationProvider";
import { Result } from "neverthrow";
import { BookingConfirmation, Translations as BookingConfirmationTranslations } from "./components/BookingConfirmation";
import { BookingDetails, Translations as BookingDetailsTranslations } from "./components/BookingDetails";
import { BookingAction, BookingResult } from "./components/shared";
import { MemberScheduledClassReason } from "src/models/booking";
import { Gym } from "src/models/gym";
import { TimetableType } from "src/models/timetableType";
import { useConfig } from "src/providers/ConfigProvider";
import { useMemberType } from "src/hooks/useMemberType";

type ModalTitle = {
  successTitle: string;
};

type BookingModalTranslations = Record<BookingAction, ModalTitle> & {
  errorTitle: string;
  capacity: {
    gymAccessSlotFull: string;
    classFull: string;
    waitingListAvailable: string;
  };
};

export type Config = {
  join: {
    url: string;
    token: string;
  };
};

export type Translations = BookingDetailsTranslations &
  BookingConfirmationTranslations & {
    shared: {
      core: {
        modalClose: string;
      };
    };
    bookingModal: BookingModalTranslations;
  };

enum BookingStatus {
  Default,
  InProgress,
  Complete,
}

export interface BookingModalProps {
  scheduledClass: ScheduledClass;
  isOpen: boolean;
  onClose: () => void;
  bookClass: (classId: EntityId) => Promise<Result<unknown, ApiError>>;
  cancelClass: (classId: EntityId, bookingId: BookingId) => Promise<Result<unknown, ApiError>>;
  redirectToUrl: (url: string) => void;
  gym: Gym;
  timetableType: TimetableType;
}

const getBookingAction = (scheduledClass: ScheduledClass): BookingAction => {
  const isBooked = scheduledClass.bookingId !== undefined;

  if (isBooked) {
    return isOnWaitingList(scheduledClass) ? BookingAction.LeaveWaitingList : BookingAction.Cancel;
  }

  const hasCapacity = scheduledClass.remainingCapacity > 0;
  const hasWaitingListCapacity = scheduledClass.remainingWaitingListCapacity > 0;
  const canJoinWaitingList = !hasCapacity && hasWaitingListCapacity;

  return canJoinWaitingList ? BookingAction.JoinWaitingList : BookingAction.Book;
};

export const BookingModal: React.FC<BookingModalProps> = ({
  scheduledClass,
  isOpen,
  onClose,
  bookClass,
  cancelClass,
  redirectToUrl,
  gym,
  timetableType,
}: BookingModalProps) => {
  const { getLabels } = useLocale();
  const labels = getLabels<Translations>();

  const [status, setStatus] = useState<BookingStatus>(BookingStatus.Default);
  const [result, setResult] = useState<BookingResult>(BookingResult.Success);

  const [bookingErrors, setBookingErrors] = useState<BookingError[]>([]);

  const modalRef = useRef<ReactModal>();
  const { mediaQueries, spacing } = useTheme();

  const isSmallScreenOrWider = useMediaQuery(mediaQueries.sm);

  /* istanbul ignore next */
  useLayoutEffect(() => {
    const reactModal = modalRef?.current;

    // Override modal overlay and content padding for small
    if (isOpen && reactModal) {
      const padding = isSmallScreenOrWider ? spacing.doubleSpacing : spacing.baseSpacing;

      const content = reactModal.portal?.content as HTMLElement;
      const overlay = reactModal.portal?.overlay as HTMLElement;

      if (content && overlay) {
        overlay.style.padding = padding;
        content.style.padding = padding;
      } else {
        if (reactModal.props.style?.overlay) reactModal.props.style.overlay.padding = padding;
        if (reactModal.props.style?.content) reactModal.props.style.content.padding = padding;
      }
    }
  }, [isOpen, isSmallScreenOrWider, status]);

  const { isAnonymous } = useMemberType();

  useEffect(() => {
    setStatus(BookingStatus.Default);
  }, [isOpen]);

  const config = useConfig();

  const handleBookingButtonClick = async () => {
    if (isAnonymous) {
      const urlFormat = config.join.url;
      const url = urlFormat.replace(config.join.token, gym.urlName);
      redirectToUrl(url);
    } else {
      setStatus(BookingStatus.InProgress);

      const apiResult: Result<unknown, ApiError> = scheduledClass.bookingId
        ? await cancelClass(scheduledClass.id, scheduledClass.bookingId)
        : await bookClass(scheduledClass.id);

      const onSuccess = () => setResult(BookingResult.Success);

      const onError = (errors: ApiError) => {
        setResult(BookingResult.Failure);

        setBookingErrors(errors.errors.map(e => e as BookingError));
      };

      setStatus(BookingStatus.Complete);

      apiResult.match(onSuccess, onError);
    }
  };

  const bookingAction = getBookingAction(scheduledClass);
  const complete = status === BookingStatus.Complete;

  const titles = labels.bookingModal[bookingAction];
  const title = result === BookingResult.Success ? titles.successTitle : labels.bookingModal.errorTitle;

  const headingText = complete ? title : scheduledClass.name;

  const getRemainingCapacity = (): number | string => {
    const isFullyBooked = scheduledClass.reason === MemberScheduledClassReason.FullyBooked;

    const capacityLabels = labels.bookingModal.capacity;

    if (isFullyBooked)
      return timetableType === "gymAccessSlot" ? capacityLabels.gymAccessSlotFull : capacityLabels.classFull;

    if (bookingAction === BookingAction.JoinWaitingList) return capacityLabels.waitingListAvailable;

    return scheduledClass.remainingCapacity;
  };

  const showAddToCalendar = (): boolean => {
    const alreadyBooked =
      status === BookingStatus.Default &&
      [BookingAction.Cancel, BookingAction.LeaveWaitingList].includes(bookingAction);

    const bookedJustNow =
      status === BookingStatus.Complete &&
      result === BookingResult.Success &&
      [BookingAction.Book, BookingAction.JoinWaitingList].includes(bookingAction);

    return alreadyBooked || bookedJustNow;
  };

  const renderClassDetails = () => {
    const isMemberOnDetailsPage = !isAnonymous && !complete;

    const onWaitingList = isOnWaitingList(scheduledClass);
    const showWaitingListPosition =
      isMemberOnDetailsPage && onWaitingList && (scheduledClass.waitingListPosition || 0) > 0;

    const isBooked = scheduledClass.bookingId !== undefined;
    const showRemainingCapacity = isMemberOnDetailsPage && !isBooked;
    const remainingCapacity = showRemainingCapacity ? getRemainingCapacity() : "";

    return (
      <ClassDetails
        showRemainingCapacity={showRemainingCapacity}
        timetableType={timetableType}
        gym={gym}
        {...scheduledClass}
        remainingCapacity={remainingCapacity}
        showAddToCalendar={showAddToCalendar()}
        showWaitingListPosition={showWaitingListPosition}
      />
    );
  };

  const renderBookingDetails = () => (
    <BookingDetails
      bookingAction={bookingAction}
      scheduledClass={scheduledClass}
      renderClassDetails={renderClassDetails}
      onBookingButtonClick={handleBookingButtonClick}
      isApiCallInProgress={status === BookingStatus.InProgress}
      timetableType={timetableType}
    />
  );

  const renderConfirmation = () => (
    <BookingConfirmation
      bookingAction={bookingAction}
      scheduledClass={scheduledClass}
      renderClassDetails={renderClassDetails}
      onBackButtonClick={onClose}
      bookingResult={result}
      timetableType={timetableType}
      bookingErrors={bookingErrors}
    />
  );

  const isAddToCalendarDropdownOpen = () =>
    modalRef.current?.portal?.content?.querySelector("[data-focus-lock-disabled='false']");

  const onRequestClose = (event: KeyboardEvent): void => {
    if (event.key !== "Escape" || !isAddToCalendarDropdownOpen()) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onRequestClose}
      headingText={headingText}
      closeText={labels.shared.core.modalClose}
      shouldCloseOnEsc={false}
      ref={modalRef}
    >
      {complete ? renderConfirmation() : renderBookingDetails()}
    </Modal>
  );
};
