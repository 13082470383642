import React from "react";
import { Heading as PureHeading, Theme } from "@puregym/ui";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { MaxWidthContainer } from "../components/MaxWidthContainer";
import type { Locale } from "config/types";

const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding-top: ${({ theme }) => theme.spacing.doubleSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.doubleSpacing};
`;

const Heading = styled(PureHeading)`
  color: ${({ theme }) => theme.colors.contrastText};
  margin-bottom: 0;
`;

type PageContext = {
  data: QueryData;
};

interface QueryData {
  site: {
    siteMetadata: {
      theme: Theme;
      locales: Locale[];
    };
  };
  urls: {
    authLogin: string;
    authLogout: string;
  };
}

const PageNotFoundPage: React.FC<PageContext> = ({ data }: PageContext): JSX.Element => {
  const { site, urls } = data;
  const { theme, locales } = site.siteMetadata;

  return (
    <Layout pageTitle="Bookings Microsite" theme={theme} supportedLocales={locales} urls={urls}>
      <Header>
        <MaxWidthContainer>
          <Heading>404 Error</Heading>
        </MaxWidthContainer>
      </Header>
      <MaxWidthContainer>
        <p>PAGE NOT FOUND</p>
      </MaxWidthContainer>
    </Layout>
  );
};

export default PageNotFoundPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        theme
        locales
      }
    }
    urls {
      authLogin
      authLogout
    }
  }
`;
