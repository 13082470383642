import { ReactElement } from "react";
import { WrapRootElementBrowserArgs } from "gatsby";
import { updateAppElement } from "@puregym/ui";
import { initialiseDayjsPlugins } from "./initialiseDayjsPlugins";
import * as bookingsApi from "src/bookingsApi/bookingsApi";
import { rootElementSelector } from "src/rootElementSelector";
import { Options } from "./types";

initialiseDayjsPlugins();
updateAppElement(rootElementSelector);

type WrapRootElementFunc = (args: WrapRootElementBrowserArgs, options: Options) => ReactElement;

export const wrapRootElement: WrapRootElementFunc = ({ element }, pluginOptions) => {
  const { locale, bookingsApiBaseUrl, brandHttpHeader } = pluginOptions;

  bookingsApi.updateConfig({
    bookingsApiBaseUrl,
    brandHttpHeader,
    locale: locale,
  });

  return element;
};
