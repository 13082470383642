import { MemberScheduledClassReason } from "src/models/booking";
import { Gym } from "src/models/gym";
import { TimeRange, Week } from "src/models/time";
import { TimetableType } from "src/models/timetableType";
import { Member, ScheduledClass } from "../../bookingsApi/bookingsApi";
import { ApiError, BookingId, EntityId } from "../../bookingsApi/models";
import { CalendarMode } from "../Calendar/Calendar";
import { SelectedClassNameFilter, SelectedClassTypeFilter } from "../ClassTypeFilter/ClassTypeFilter";

// Actions
const SET_CLASSES_STARTED = "SET_CLASSES_STARTED";
const SET_CLASSES_SUCCEEDED = "SET_CLASSES_SUCCEEDED";
const SET_CLASSES_FAILED = "SET_CLASSES_FAILED";
const GET_MEMBER_DETAILS_SUCCEEDED = "GET_MEMBER_DETAILS_SUCCEEDED";
const GET_MEMBER_DETAILS_FAILED = "GET_MEMBER_DETAILS_FAILED";
const SET_CALENDAR_MODE = "SET_CALENDAR_MODE";
const SELECT_WEEK = "SELECT_WEEK";
const SET_NAME_FILTER = "SET_NAME_FILTER";
const SET_TYPE_FILTER = "SET_TYPE_FILTER";
const SET_TIMERANGE_FILTER = "SET_TIMERANGE_FILTER";
const SELECT_GYM = "SELECT_GYM";
const CLASS_BOOKED = "CLASS_BOOKED";
const DESELECT_GYM = "DESELECT_GYM";
export const CLASS_CANCELLED = "CLASS_CANCELLED";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const DESELECT_CLASS = "DESELECT_CLASS";

export const REDIRECT_TO_URL = "REDIRECT_TO_URL";

export interface Labels {
  timetable: {
    pageHeading: string;
    noBookableActivitiesOnThisDay: string;
    genericApiError: string;
  };
  shared: {
    core: {
      loading: string;
    };
  };
}

export interface TimetableState {
  scheduledClasses: ScheduledClass[];
  calendarMode: CalendarMode;
  isLoading: boolean;
  isInitialized: boolean;
  getClassesError: ApiError | null;
  getMemberDetailsError: ApiError | null;
  gym?: Gym;
  labels: Labels;
  currentWeek: Week;
  filters: {
    className: SelectedClassNameFilter | null;
    classType: SelectedClassTypeFilter | null;
    timeRange: TimeRange | null;
  };
  timetableType: TimetableType;
  isModalOpen: boolean;
  currentClass: ScheduledClass | null;
  member?: Member;
}

// initial state
export const initialState: Pick<
  TimetableState,
  | "scheduledClasses"
  | "calendarMode"
  | "isLoading"
  | "currentWeek"
  | "filters"
  | "getClassesError"
  | "getMemberDetailsError"
  | "isInitialized"
  | "isModalOpen"
  | "currentClass"
> = {
  scheduledClasses: [],
  calendarMode: "WeekView",
  isLoading: true,
  isInitialized: false,
  getClassesError: null,
  getMemberDetailsError: null,
  currentWeek: "current",
  filters: {
    className: null,
    classType: null,
    timeRange: null,
  },
  isModalOpen: false,
  currentClass: null,
};

const setBookingId = (scheduledClass: ScheduledClass, bookingId: BookingId): ScheduledClass => ({
  ...scheduledClass,
  bookingId,
  reason: MemberScheduledClassReason.AlreadyBooked,
});

const setCancelled = (scheduledClass: ScheduledClass): ScheduledClass => ({
  ...scheduledClass,
  bookingId: undefined,
  reason: MemberScheduledClassReason.NotSet,
});

// reducers
export default function (state: TimetableState, action: Action): TimetableState {
  switch (action.type) {
    case SET_CLASSES_STARTED:
      return {
        ...state,
        isLoading: true,
        getClassesError: null,
      };

    case SET_CLASSES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        scheduledClasses: action.scheduledClasses,
      };
    case SET_CLASSES_FAILED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        getClassesError: action.error,
        scheduledClasses: [],
      };

    case GET_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        getMemberDetailsError: action.error,
        scheduledClasses: [],
      };

    case GET_MEMBER_DETAILS_SUCCEEDED:
      return {
        ...state,
        member: action.member,
      };

    case SET_CALENDAR_MODE:
      return {
        ...state,
        calendarMode: action.calendarMode,
      };

    case SELECT_WEEK:
      return {
        ...state,
        currentWeek: action.currentWeek,
      };

    case SET_NAME_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          className: action.filter,
          classType: null,
        },
      };

    case SET_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          className: null,
          classType: action.filter,
        },
      };

    case SET_TIMERANGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          timeRange: action.filter,
        },
      };

    case SELECT_GYM:
      return {
        ...state,
        gym: action.gym,
        isInitialized: false,
      };

    case CLASS_BOOKED:
      return {
        ...state,
        scheduledClasses: state.scheduledClasses.map(c =>
          c.id === action.scheduledClassId ? setBookingId(c, action.bookingId) : c
        ),
      };

    case CLASS_CANCELLED:
      return {
        ...state,
        scheduledClasses: state.scheduledClasses.map(c => (c.id === action.scheduledClassId ? setCancelled(c) : c)),
      };

    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        currentClass: action.scheduledClass,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        currentClass: null,
      };

    case DESELECT_GYM:
      return {
        ...state,
        gym: { id: action.gymId } as Gym,
        isInitialized: true,
        isLoading: false,
        scheduledClasses: [],
      };
    default:
      return state;
  }
}

// action creators
export const setClassesStarted = () => {
  return {
    type: SET_CLASSES_STARTED,
  } as const;
};

export const setClassesSucceeded = (scheduledClasses: ScheduledClass[]) => {
  return {
    type: SET_CLASSES_SUCCEEDED,
    scheduledClasses,
  } as const;
};

export const setClassesFailed = (error: ApiError) => {
  return {
    type: SET_CLASSES_FAILED,
    error,
  } as const;
};

export const setMemberDetailsSucceded = (member: Member) => {
  return {
    type: GET_MEMBER_DETAILS_SUCCEEDED,
    member,
  } as const;
};

export const setMemberDetailsFailed = (error: ApiError) => {
  return {
    type: GET_MEMBER_DETAILS_FAILED,
    error,
  } as const;
};

export const setCalendarMode = (calendarMode: CalendarMode) => {
  return {
    type: SET_CALENDAR_MODE,
    calendarMode,
  } as const;
};

export const setNameFilter = (filter: SelectedClassNameFilter) => {
  return {
    type: SET_NAME_FILTER,
    filter,
  } as const;
};

export const setTypeFilter = (filter: SelectedClassTypeFilter | null) => {
  return {
    type: SET_TYPE_FILTER,
    filter,
  } as const;
};

export const setTimeRangeFilter = (filter: TimeRange | null) => {
  return {
    type: SET_TIMERANGE_FILTER,
    filter,
  } as const;
};

export const redirectToUrl = (url: string) => {
  return {
    type: REDIRECT_TO_URL,
    url,
  } as const;
};

export const selectWeek = (week: Week) => {
  return {
    type: SELECT_WEEK,
    currentWeek: week,
  } as const;
};

export const selectGym = (gym: Gym) => {
  return {
    type: SELECT_GYM,
    gym,
  } as const;
};

export const classBooked = (scheduledClassId: EntityId, bookingId: BookingId) => {
  return {
    type: CLASS_BOOKED,
    scheduledClassId,
    bookingId,
  } as const;
};

export const classCancelled = (scheduledClassId: EntityId) => {
  return {
    type: CLASS_CANCELLED,
    scheduledClassId,
  } as const;
};

export const openModal = (scheduledClass: ScheduledClass) => {
  return {
    type: OPEN_MODAL,
    scheduledClass,
  } as const;
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  } as const;
};

export const deselectClass = () => {
  return {
    type: DESELECT_CLASS,
  } as const;
};

export const deselectGym = (gymId: number) => {
  return {
    type: DESELECT_GYM,
    gymId: gymId,
  } as const;
};

export type Action =
  | ReturnType<typeof setClassesStarted>
  | ReturnType<typeof setClassesSucceeded>
  | ReturnType<typeof setClassesFailed>
  | ReturnType<typeof setMemberDetailsFailed>
  | ReturnType<typeof setMemberDetailsSucceded>
  | ReturnType<typeof setCalendarMode>
  | ReturnType<typeof selectWeek>
  | ReturnType<typeof setNameFilter>
  | ReturnType<typeof setTypeFilter>
  | ReturnType<typeof setTimeRangeFilter>
  | ReturnType<typeof redirectToUrl>
  | ReturnType<typeof selectGym>
  | ReturnType<typeof deselectGym>
  | ReturnType<typeof classBooked>
  | ReturnType<typeof classCancelled>
  | ReturnType<typeof openModal>
  | ReturnType<typeof closeModal>
  | ReturnType<typeof deselectClass>;
