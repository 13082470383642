import React from "react";
import { TimetableInitialValue, TimetableProvider } from "src/components/Timetable/context";
import { Timetable as TimetableComponent } from "src/components/Timetable/Timetable";
import { Gym } from "../../models/gym";
import { TimetableType, TimetableTypes } from "../../models/timetableType";
import { StaticConfigProvider } from "src/providers/StaticConfigProvider";
import { StaticLocalizationProvider } from "src/providers/StaticLocalizationProvider";
import { shape, number, string, oneOf } from "prop-types";
import { Member } from "src/bookingsApi/bookingsApi";

type MemberTimetableProps = {
  gym?: Gym;
  type: TimetableType;
  introductionText?: string | null;
  allGyms: Gym[];
  onGymSelected?: (gymId: number) => void;
  member?: Member;
};

type PublicTimetableProps = {
  gym: Gym;
  type: TimetableType;
  introductionText?: string | null;
  allowAnonymousUsers?: boolean;
};

export type TimetableProps = MemberTimetableProps | PublicTimetableProps;

export const Timetable: React.FC<TimetableProps> = (props: TimetableProps) => {
  const { gym, type, ...otherProps } = props;

  const initialValue: TimetableInitialValue = { gym, timetableType: type };

  const allowAnonymousUsers = "allowAnonymousUsers" in props && props.allowAnonymousUsers;

  return (
    <StaticLocalizationProvider>
      <StaticConfigProvider allowAnonymousUsers={allowAnonymousUsers}>
        <TimetableProvider initialValue={initialValue}>
          <TimetableComponent {...otherProps} />
        </TimetableProvider>
      </StaticConfigProvider>
    </StaticLocalizationProvider>
  );
};

const gym = shape({
  id: number.isRequired,
  name: string.isRequired,
  urlName: string.isRequired,
  address: shape({
    address1: string.isRequired,
    address2: string,
    address3: string,
    town: string.isRequired,
    postcode: string.isRequired,
  }).isRequired,
});

Timetable.propTypes = {
  gym,
  type: oneOf([TimetableTypes.scheduledClass, TimetableTypes.gymAccessSlot]).isRequired,
  introductionText: string,
};
