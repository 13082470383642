import { Result } from "neverthrow";
import { Dayjs } from "dayjs";
import { MemberScheduledClassReason } from "src/models/booking";
import { Gym } from "src/models/gym";

export type BookingError = {
  errorCode: WellKnownBookingRuleErrorMessageIds;
  message: string;
  extraErrorInfo?: Record<string, string | number>;
};

export type ApiError = {
  message: string;
  statusCode: number;
  errors: BookingError[];
};

export type ApiResult<T> = Result<T, ApiError>;

type Container<T> = { value: T };

export class BookingId implements Container<string> {
  kind: "BookingId" = "BookingId";
  value: string;
  constructor(value: string) {
    this.value = value;
  }
  toString(): string {
    return this.value;
  }
}

export class EntityId implements Container<string> {
  kind: "EntityId" = "EntityId";
  value: string;
  constructor(value: string) {
    this.value = value;
  }
  toString(): string {
    return this.value;
  }

  equals(other: EntityId | null | undefined): boolean {
    return other?.kind === this.kind && other.value === this.value;
  }
}

export type Minute = number;

export interface GetScheduledClassesResponse {
  scheduledClasses: ScheduledClass[];
}

export interface GetGymAccessSlotsResponse {
  gymAccessSlots: ScheduledClass[];
}

export interface ScheduledClass {
  name: string;
  duration: Minute;
  bookingId?: BookingId;
  id: EntityId;
  instructorName: string;
  startDateTime: Dayjs;
  studio?: string;
  classType: string;
  remainingCapacity: number;
  remainingWaitingListCapacity: number;
  reason: MemberScheduledClassReason;
  energyConsumptionKcal?: number;
  description?: string;
  waitingListPosition?: number | null;
  bookingErrors: BookingError[];
}

export interface BookedClass extends ScheduledClass {
  gym: Gym;
}

export type GetActivitiesJsonResponse<T> = {
  activities: T[];
};

export type GetScheduledClassesJsonResponse = GetActivitiesJsonResponse<ScheduledClassJson>;

export interface GetBookingsJsonResponse {
  bookings: BookedClassJson[];
}

export type GetGymAccessSlotsJsonResponse = GetActivitiesJsonResponse<GymAccessSlotJson>;

interface ActivityJson {
  id: string;
  name: string;
  startDateTime: {
    dateTime: string;
    timeZone: string;
  };
  duration: number;
  description?: string;

  bookingId?: string;
  bookedCount: number;
  classCapacity: number;
  waitingListCount: number;
  waitingListCapacity: number;
  waitingListPosition?: number | null;

  bookingStatus: MemberScheduledClassReason;

  bookingErrors?: BookingError[];
}

export interface ApiErrorJson {
  errors: {
    errorCode: string;
    message: string;
    extraErrorInfo?: unknown;
  }[];
}

export enum WellKnownBookingRuleErrorMessageIds {
  MemberMustBeLoggedIn = "MemberMustBeLoggedIn",
  GymAccessSlotRequiredToBookThisClass = "GymAccessSlotRequiredToBookThisClass",
  ClassTooFarInAdvance = "ClassTooFarInAdvance",
  ClassHasAlreadyStarted = "ClassHasAlreadyStarted",
  AlreadyOnWaitingListForThisClass = "AlreadyOnWaitingListForThisClass",
  ClassIsNonBookable = "ClassIsNonBookable",
  ClassNoLongerAvailableForBooking = "ClassNoLongerAvailableForBooking",
  AnotherClassBookedAtSameTime = "AnotherClassBookedAtSameTime",
  ClassFullyBooked = "ClassFullyBooked",
  ClassAlreadyBooked = "ClassAlreadyBooked",
  CourseHasAlreadyStarted = "CourseHasAlreadyStarted",
  CourseOfSameTypeAlreadyBooked = "CourseOfSameTypeAlreadyBooked",
  CancelClassBookingBeforeCancelingGymSlot = "CancelClassBookingBeforeCancelingGymSlot",
  GymSlotHasAlreadyStarted = "GymSlotHasAlreadyStarted",
  CanOnlyBookCoursesUpgradeToBookClasses = "CanOnlyBookCoursesUpgradeToBookClasses",
  CanOnlyAccessPgTogetherUpgradeToBookClasses = "CanOnlyAccessPgTogetherUpgradeToBookClasses",
  NotAMemberOfThisGymUpgradeToBookClasses = "NotAMemberOfThisGymUpgradeToBookClasses",
  MembershipNotValidForThisGym = "MembershipNotValidForThisGym",
  MembershipNotValidDuringClassTime = "MembershipNotValidDuringClassTime",
  MembershipNotActive = "MembershipNotActive",
  MembershipExpiresBeforeEndOfCourse = "MembershipExpiresBeforeEndOfCourse",
  OffPeakMembershipNotValidForThisTimeOfDay = "OffPeakMembershipNotValidForThisTimeOfDay",
  OffPeakMembershipNotValidForThisGym = "OffPeakMembershipNotValidForThisGym",
  ContactMemberServicesToBookPureLoserClass = "ContactMemberServicesToBookPureLoserClass",
  TooManyClassesBooked = "TooManyClassesBooked",
  ClassNotBooked = "ClassNotBooked",
  ClassAlreadyCancelled = "ClassAlreadyCancelled",
  ClassNotFound = "ClassNotFound",
  TooLateToCancel = "TooLateToCancel",
  UnspecifiedError = "UnspecifiedError",
  MembershipDoesNotAllowParticipationInThisClass = "MembershipDoesNotAllowParticipationInThisClass",
  MemberNotOldEnoughToBookClass = "MemberNotOldEnoughToBookClass",
}

export interface ScheduledClassJson extends ActivityJson {
  instructorName: string;
  studio?: string;
  classType: string;
  energyConsumptionKcal?: number;
}

export interface BookedClassJson extends ScheduledClassJson {
  gym: Gym;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GymAccessSlotJson extends ActivityJson {}

export const ClassTypes = <const>{
  digital: "digital",
  induction: "induction",
  standard: "standard",
};

export const GymAccessSlotType = "gym access slot";

export type ActivityType = keyof typeof ClassTypes | typeof GymAccessSlotType;

export const isOnWaitingList = <T extends ScheduledClass>(scheduledClass: T): boolean =>
  scheduledClass.reason === MemberScheduledClassReason.AlreadyOnWaitingList;
