import React from "react";
import { SelectField } from "@puregym/ui";
import { Gym } from "src/models/gym";
import { useLocale } from "src/providers/LocalizationProvider";
import { GetMemberDetailsResponse } from "src/bookingsApi/bookingsApi";

export type Translations = {
  gymFilter: {
    title: string;
    pleaseSelect: string;
    yourGyms: string;
  };
};

export interface SelectedGymFilter {
  gymId: number;
}

export type GymSelectedCallback = (filter: SelectedGymFilter) => void;

export interface GymFilterProps {
  selectedGymId: number;
  member: GetMemberDetailsResponse | undefined;
  gyms: Gym[];
  onGymSelected: GymSelectedCallback;
}

const GymFilter: React.FC<GymFilterProps> = ({ gyms, selectedGymId, member, onGymSelected }: GymFilterProps) => {
  const { getLabels } = useLocale();
  const { gymFilter: labels } = getLabels<Translations>();

  const gymsSorted = [...gyms].sort((gym1, gym2) => gym1.name.localeCompare(gym2.name));
  const yourGyms = gymsSorted.filter(gym => {
    return gym.id === member?.primaryGymId || gym.id === member?.secondaryGymId;
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    const gymId = Number(selectedValue);
    onGymSelected({ gymId });
  };

  return (
    <SelectField label={labels.title} name="gymFilter" value={selectedGymId} onChange={onChange}>
      {yourGyms.length < 1 && <option value={"-1"}> {labels.pleaseSelect} </option>}
      {yourGyms.length > 0 && (
        <optgroup label={labels.yourGyms} hidden={yourGyms.length == 0}>
          {yourGyms.map((gym, i) => (
            <option key={i} value={gym.id}>
              {gym.name}
            </option>
          ))}
        </optgroup>
      )}
      {gymsSorted.map((gym, i) => (
        <option key={i + 2} value={gym.id}>
          {gym.name}
        </option>
      ))}
    </SelectField>
  );
};

export const LoadingGymFilter: React.FC = () => {
  const { getLabels } = useLocale();
  const { gymFilter: labels } = getLabels<Translations>();

  return <SelectField label={labels.title} name="gymFilter"></SelectField>;
};

const GymFilterMemoized = React.memo<GymFilterProps>(GymFilter);

export { GymFilterMemoized as GymFilter };
