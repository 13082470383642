import dayjs, { Dayjs } from "dayjs";
import { Week } from "src/models/time";
import dateUtils, { getNumberOfDaysBetween } from "../../utils/dateUtils";
import { Range } from "../../utils/range";

const daysPerWeek = 8;

export const getCalendarDays = (activityDates: Dayjs[], isWeekView: boolean, currentWeek: Week): Dayjs[] => {
  if (!activityDates.length) {
    const today = dayjs();
    return getCalendarDays([today], isWeekView, currentWeek);
  }

  const firstDate = activityDates.reduce(dateUtils.min, activityDates[0]);
  const lastDate = activityDates.reduce(dateUtils.max, activityDates[0]);

  const numberOfDaysToShow = isWeekView
    ? daysPerWeek
    : Math.max(daysPerWeek, getNumberOfDaysBetween(firstDate, lastDate));

  const firstCalendarDay = isWeekView && currentWeek === "next" ? firstDate.add(daysPerWeek, "day") : firstDate;
  const calendarDays = Range.of(numberOfDaysToShow).map(day => firstCalendarDay.add(day, "day"));

  return calendarDays;
};
