import React from "react";
import { SelectField } from "@puregym/ui";
import { ClassTypes } from "src/bookingsApi/models";
import { useLocale } from "src/providers/LocalizationProvider";

export type Translations = {
  classTypeFilter: {
    title: string;
    all: string;
    inductions: string;
    classes: string;
    digitalClasses: string;
    classTypeGroupLabel: string;
    classNameGroupLabel: string;
  };
};

export interface SelectedClassTypeFilter {
  classType: string;
  negate?: boolean;
}

export interface SelectedClassNameFilter {
  className: string;
}

export type ClassTypeFilterSelection = SelectedClassNameFilter | SelectedClassTypeFilter;

export type ClassNameSelectedCallback = (value: SelectedClassNameFilter) => void;

export type ClassTypeSelectedCallback = (filter: SelectedClassTypeFilter | null) => void;

export interface ClassTypeFilterProps {
  classNames: string[];
  onClassNameSelected: ClassNameSelectedCallback;
  onClassTypeSelected: ClassTypeSelectedCallback;
}

const ClassTypeFilter: React.FC<ClassTypeFilterProps> = ({
  classNames,
  onClassTypeSelected,
  onClassNameSelected,
}: ClassTypeFilterProps) => {
  const { getLabels, locale } = useLocale();
  const { classTypeFilter: labels } = getLabels<Translations>();

  const staticOptions: Array<{ name: string; value: string; filter: SelectedClassTypeFilter | null }> = [
    { name: labels.all, value: "all", filter: null },
    { name: labels.inductions, value: ClassTypes.induction, filter: { classType: ClassTypes.induction } },
    { name: labels.classes, value: ClassTypes.standard, filter: { classType: ClassTypes.induction, negate: true } },
    { name: labels.digitalClasses, value: ClassTypes.digital, filter: { classType: ClassTypes.digital } },
  ];

  const optionLookup = Object.fromEntries(staticOptions.map(option => [option.value, option]));

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;

    if (classNames.includes(selectedValue)) {
      onClassNameSelected({ className: selectedValue });
    } else {
      onClassTypeSelected(optionLookup[selectedValue].filter);
    }
  };

  const showClassNamesGroup = classNames.length > 0;
  const classNamesSorted = [...classNames].sort((c1, c2) => c1.localeCompare(c2, locale));

  const renderClassNamesGroup = () => {
    return (
      <optgroup label={labels.classNameGroupLabel} aria-label={labels.classNameGroupLabel}>
        {classNamesSorted.map(name => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </optgroup>
    );
  };

  return (
    <SelectField label={labels.title} name="classTypeFilter" onChange={onChange}>
      <optgroup label={labels.classTypeGroupLabel} aria-label={labels.classTypeGroupLabel}>
        {staticOptions.map(({ name, value }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </optgroup>
      {showClassNamesGroup && renderClassNamesGroup()}
    </SelectField>
  );
};

const ClassTypeFilterMemoized = React.memo<ClassTypeFilterProps>(ClassTypeFilter);

export { ClassTypeFilterMemoized as ClassTypeFilter };
