import React from "react";
import { Config as BookedClassesConfig } from "../components/BookedClasses/BookedClasses";
import { Config as BookingModalConfig } from "../components/BookingModal/BookingModal";
import { Config as AddToCalendarConfig } from "../components/AddToCalendar/AddToCalendar";

export type Config = BookedClassesConfig & BookingModalConfig & AddToCalendarConfig & AuthConfig;

export type AuthConfig = {
  allowAnonymousUsers: boolean;
};

const ConfigContext = React.createContext<Config | null>(null);

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type ConfigProviderProps = {
  config: Optional<Config, "allowAnonymousUsers">;
  children: React.ReactNode;
};

export const ConfigProvider = ({ config, children }: ConfigProviderProps) => {
  const value: Config = {
    ...config,
    allowAnonymousUsers: config.allowAnonymousUsers || false,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export function useConfig<T extends Partial<Config> = Config>(): T {
  const data = React.useContext(ConfigContext);

  if (data === null) throw new Error("Config Context not initialized");

  return data as T;
}
