const isBrowser = typeof window !== "undefined";

export const navigate = (url: string): void => {
  if (!isBrowser) {
    return;
  }
  window.location.href = url;
};

export const getBaseUrl = (): string => (isBrowser ? window.location.origin : "");
export const getCurrentUrl = (): string => (isBrowser ? window.location.href : "");

export const withReturnUrl = (authUrl: string): string => {
  const returnUrl = getCurrentUrl();

  const url = new URL(authUrl);
  url.searchParams.set("returnUrl", returnUrl);

  const formatted = url.toString();
  return formatted;
};

const absolutUrlRegex = /^https?:\/\/|^\/\//i;

export const isRelativeUrl = (url: string): boolean => !absolutUrlRegex.test(url);

export const isAbsoluteUrl = (url: string): boolean => absolutUrlRegex.test(url);
