import React from "react";
import { Dayjs } from "dayjs";
import styled, { css } from "styled-components";
import themeFor from "styled-theming";
import { replaceTokens } from "../../utils/stringUtils";
import { BookingError, BookingId, ClassTypes, EntityId } from "src/bookingsApi/models";
import { ColumnStyles, listViewStyles } from "../../styles/listViewStyles";
import { CalendarCardStatus } from "./CalendarCardStatus";
import { MemberScheduledClassReason } from "src/models/booking";
import { darken } from "polished";
import { TimetableType } from "src/models/timetableType";
import { useLocale } from "src/providers/LocalizationProvider";

export interface IScheduledClass {
  id: EntityId;
  name: string;
  bookingId?: BookingId;
  duration: number;
  instructorName: string;
  startDateTime: Dayjs;
  studio?: string;
  remainingCapacity: number;
  remainingWaitingListCapacity: number;
  reason: MemberScheduledClassReason;
  classType: string;
  bookingErrors: BookingError[];
}

export type CalendarCardProps = {
  scheduledClass: IScheduledClass;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  isWeekView: boolean;
  variant: TimetableType;
};

interface BaseCardProps {
  isWeekView: boolean;
  isListView: boolean;
}

interface CardProps extends BaseCardProps {
  status: MemberScheduledClassReason;
  columns: ColumnStyles;
  isAuthenticated: boolean;
}

export const BaseCard = styled.div<BaseCardProps>`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.xsmall || "1.4rem"};

  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        align-items: center;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 160px;
      }

      ${({ theme }) => theme.mediaQueries.xl} {
        min-height: 180px;
      }
    `}
`;

const Cell = styled.div<CardProps>`
  margin-bottom: 6px;

  ${({ isListView }) =>
    isListView &&
    css`
      display: flex;
      align-items: center;

      ${({ theme }) => theme.mediaQueries.lg} {
        margin-bottom: 0;
      }
    `}
`;

const ClassName = styled(Cell)`
  font-size: ${({ theme }) => theme.fontSizes.standard};
  font-family: ${({ theme }) => theme.fonts.heading};
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: ${({ theme }) => theme.fontSizes.standard};

  ${({ isListView }) =>
    isListView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        margin-bottom: 0;
      }
    `}

  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    `}
`;

const Time = styled(Cell)<CardProps>`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.heading};

  ${({ status }: CardProps) =>
    status === MemberScheduledClassReason.AlreadyBooked &&
    css`
      color: ${props => props.theme.colors.primary.contrastText};
    `}
`;

const Studio = styled(Cell)<CardProps>`
  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        display: none;
      }
    `}
`;

const Instructor = styled(Cell)<CardProps>`
  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        flex: 1;
      }
    `}
`;

const CardMain = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 15px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 10px 0px;
  }

  ${({ isWeekView }) =>
    !isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        flex-direction: row;
      }
    `};
`;

export const Card = styled(BaseCard)<CardProps>`
  line-height: 1;
  transition: color ${({ theme }) => theme.transitionsMs.default}, min-height ${({ theme }) => theme.transitionsMs.fast};
  cursor: pointer;
  overflow: hidden;

  color: ${({ theme, isAuthenticated }) => (isAuthenticated ? theme.colors.text : theme.colors.textMuted)};

  &:hover {
    transition: none;
    background-color: ${({ theme }) => theme.colors.accents.light};
    color: ${({ theme }) =>
      themeFor("mode", {
        dark: theme.colors.primary.contrastTextLight,
      })};
  }

  ${({ isWeekView }: CardProps) =>
    isWeekView &&
    css`
      flex-direction: row;

      ${({ theme }) => theme.mediaQueries.lg} {
        display: flex !important;
        flex-direction: column !important;
      }
    `}

  ${({ isWeekView, columns }: CardProps) =>
    !isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline-start: ${listViewStyles.rowPaddingHorizontal};
        padding-inline-end: 0;

        ${ClassName} {
          ${columns.className};
        }

        ${Time} {
          ${columns.time};
        }

        ${Studio} {
          ${columns.studio};
        }

        ${Instructor} {
          ${columns.instructor};
        }
      }
    `}

    ${({ status }: CardProps) =>
    status === MemberScheduledClassReason.AlreadyBooked &&
    css`
      background-color: ${props => props.theme.colors.primary.main};
      color: ${props => props.theme.colors.primary.contrastText};

      &:hover {
        background-color: ${props => props.theme.colors.primary.dark};
      }
    `}

    ${({ status }: CardProps) =>
    status === MemberScheduledClassReason.AlreadyOnWaitingList &&
    css`
      background-color: ${props => props.theme.colors.accents.light};

      &:hover {
        background-color: ${props => darken(0.1, props.theme.colors.accents.light)};
      }
    `}
`;

export type Settings = {
  calendarCard: {
    timeFormat: string;
  };
};

export type Translations = {
  calendarCard: {
    duration: string;
    accessible: {
      duration: string;
    };
    classType: {
      digital: string;
      induction: string;
    };
  };
};

const defaultTimeFormat = "HH:mm";

const formatClassStartTime = (date: Dayjs) => {
  const { formatDateWithPattern: formatDate } = useLocale();
  const getDateFormat = (settings: Settings) => settings.calendarCard.timeFormat;
  return formatDate(date, getDateFormat, defaultTimeFormat);
};

export const CalendarCard: React.FC<CalendarCardProps> = ({
  scheduledClass,
  variant,
  isWeekView,
  isLoading = false,
  isAuthenticated = false,
}: CalendarCardProps) => {
  const { getLabels } = useLocale();
  const { calendarCard: labels } = getLabels<Translations>();

  const { name: gymClassName, startDateTime, duration, studio, instructorName, reason } = scheduledClass;

  const formattedTime = formatClassStartTime(startDateTime);
  const formattedDuration = replaceTokens(labels.duration, { duration });
  const ariaLabelDuration = replaceTokens(labels.accessible.duration, { duration });

  const showStudio = variant !== "gymAccessSlot";
  const showInstructor = variant !== "gymAccessSlot";

  const columnStyles = listViewStyles.columns[variant];

  const cardProps: CardProps = {
    isWeekView: isWeekView,
    isListView: !isWeekView,
    status: reason,
    columns: columnStyles,
    isAuthenticated,
  };

  const classTypeLabels: Record<string, string> = {
    [ClassTypes.digital]: labels.classType.digital,
    [ClassTypes.induction]: labels.classType.induction,
  };

  const calendarStatusProps = {
    ...cardProps,
    ...scheduledClass,
    isLoading,
    label: classTypeLabels[scheduledClass.classType],
  };

  const showCalendarCardStatus = isAuthenticated;

  const renderContent = () => (
    <>
      <CardMain {...cardProps}>
        <ClassName {...cardProps} data-testid="classname">
          {gymClassName}
        </ClassName>
        <Time {...cardProps}>
          <time>{formattedTime}</time>
          <span aria-label={ariaLabelDuration}>&nbsp;({formattedDuration})</span>
        </Time>
        {showStudio && <Studio {...cardProps}>{studio}</Studio>}
        {showInstructor && <Instructor {...cardProps}>{instructorName}</Instructor>}
      </CardMain>
      {showCalendarCardStatus && <CalendarCardStatus {...calendarStatusProps} />}
    </>
  );

  return (
    <Card {...cardProps} data-testid="card" data-classtype={scheduledClass.classType}>
      {renderContent()}
    </Card>
  );
};
