import { PluginFunc } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    /**       
      @returns the name of the time zone 
     */
    getTimeZoneName(): string;
  }
}

const getTimeZoneName: PluginFunc = (_option, dayjsClass) => {
  dayjsClass.prototype.getTimeZoneName = function () {
    const instance = this as any;
    return instance.$x.$timezone;
  };
};

export default getTimeZoneName;
