import React, { ReactElement } from "react";
import { GatsbyBrowser, WrapPageElementBrowserArgs } from "gatsby";
import { AuthenticatedPage } from "src/components/AuthenticatedPage";
import { AuthenticatedPageContext } from "./config/gatsby/pages/createMemberPages";

export const wrapPageElement: Required<GatsbyBrowser>["wrapPageElement"] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<Record<string, unknown>, AuthenticatedPageContext>): ReactElement => {
  if (props.pageContext.requireLogin) {
    return <AuthenticatedPage>{element}</AuthenticatedPage>;
  }

  return element;
};
