import React, { ReactNode } from "react";
import styled from "styled-components";
import { Text, visuallyHiddenStyles } from "@puregym/ui";
import { ModalContent, BookingAction } from "./shared";
import { BookingButton } from "./BookingButton";
import { ScheduledClass } from "src/bookingsApi/models";
import { useLocale } from "src/providers/LocalizationProvider";
import { MemberScheduledClassReason } from "src/models/booking";
import { TimetableType } from "src/models/timetableType";
import { BookingErrors } from "./BookingErrors";
import { replaceTokens } from "src/utils/stringUtils";
import { useMemberType } from "src/hooks/useMemberType";

const Description = styled(Text)`
  font-size: ${props => props.theme.fontSizes.small};
  margin-bottom: 0;
`;

const AriaHeader = styled.h2`
  ${visuallyHiddenStyles};
`;

const FullyBookedMessage = styled.div`
  margin-bottom: ${props => props.theme.spacing.doubleSpacing};
  padding: ${({ theme }) => `${theme.spacing.halfSpacing} ${theme.spacing.threeQuarterSpacing}`};
  border-radius: ${({ theme }) => theme.radii.default};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.standard};
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.accents.muted};
  color: ${({ theme }) => theme.colors.opacities.invertedStrong};
`;

type FullyBookedMessages = {
  [key in TimetableType]: {
    fullyBooked: string;
  };
};

export type Translations = {
  bookingModal: {
    bookingFailureReasons: string;
    accessible: {
      descriptionTitle: string;
    };
    gymAccessSlot: {
      name: string;
    };
    scheduledClass: {
      name: string;
    };
  } & FullyBookedMessages;
};

type BookingDetailsProps = {
  renderClassDetails: () => ReactNode | ReactNode[];
  bookingAction: BookingAction;
  scheduledClass: ScheduledClass;
  onBookingButtonClick: () => void;
  isApiCallInProgress: boolean;
  timetableType: TimetableType;
};

export const BookingDetails: React.FC<BookingDetailsProps> = ({
  scheduledClass,
  bookingAction,
  renderClassDetails,
  onBookingButtonClick,
  isApiCallInProgress,
  timetableType,
}: BookingDetailsProps) => {
  const { getLabels } = useLocale();
  const { bookingModal: labels } = getLabels<Translations>();

  const { isAnonymous } = useMemberType();

  const isFullyBooked = scheduledClass.reason === MemberScheduledClassReason.FullyBooked;

  const renderButton = () => (
    <BookingButton
      onClick={onBookingButtonClick}
      isLoading={isApiCallInProgress}
      action={bookingAction}
      scheduledClass={scheduledClass}
    />
  );

  const renderFullyBookedMessage = () => {
    const message = labels[timetableType].fullyBooked;
    return <FullyBookedMessage role="status">{message}</FullyBookedMessage>;
  };

  const showBookingErrors =
    !isAnonymous &&
    scheduledClass.bookingErrors.length &&
    [BookingAction.Book, BookingAction.JoinWaitingList].includes(bookingAction);

  const statusMessage = replaceTokens(labels.bookingFailureReasons, {
    bookingType: labels[timetableType].name,
  });

  return (
    <ModalContent>
      {renderClassDetails()}

      {isFullyBooked ? (
        renderFullyBookedMessage()
      ) : showBookingErrors ? (
        <BookingErrors bookingErrors={scheduledClass.bookingErrors} statusMessage={statusMessage} />
      ) : (
        renderButton()
      )}

      <AriaHeader id="bookingModal_classDescription" aria-hidden>
        {labels.accessible.descriptionTitle}
      </AriaHeader>
      <Description
        aria-labelledby="bookingModal_classDescription"
        data-testid="classDescription"
        dangerouslySetInnerHTML={{
          __html: scheduledClass.description,
        }}
      ></Description>
    </ModalContent>
  );
};
