const bookingsRoute = "/bookings";
const timetableRoute = "/timetable";
const memberDetailsRoute = "/member-details";

enum TimetableTypes {
  GymClass = "scheduled-class",
  GymAccessSlot = "gym-access-slot",
}

export const urls = <const>{
  scheduledClasses: `${timetableRoute}/:gymId/${TimetableTypes.GymClass}`,
  gymAccessSlots: `${timetableRoute}/:gymId/${TimetableTypes.GymAccessSlot}`,
  cancelBooking: `${bookingsRoute}/:bookingId`,
  bookClass: bookingsRoute,
  bookings: bookingsRoute,
  memberDetails: memberDetailsRoute,
};
