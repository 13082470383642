import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Config, ConfigProvider, AuthConfig } from "./ConfigProvider";

type QueryData = {
  site: {
    siteMetadata: Config;
  };
};

type StaticConfigProviderProps = Partial<AuthConfig> & {
  children: React.ReactNode;
};

export const StaticConfigProvider = ({ children, ...props }: StaticConfigProviderProps) => {
  const { site } = useStaticQuery<QueryData>(graphql`
    query {
      site {
        siteMetadata {
          join {
            url
            token
          }
          memberTimetablePageUrl
          brandDisplayName
          memberServicesEmailAddress
        }
      }
    }
  `);

  const config = {
    ...site.siteMetadata,
    ...props,
  };

  return <ConfigProvider config={config}>{children}</ConfigProvider>;
};
