import React, { useEffect, useMemo, useCallback, useRef } from "react";
import styled from "styled-components";
import {
  getScheduledClasses,
  getGymAccessSlots,
  bookClass,
  cancelBooking,
  ScheduledClass,
  EntityId,
  BookingId,
  isRequestCancelled,
  getMemberDetails,
  Gender,
  Member,
} from "src/bookingsApi/bookingsApi";
import { CalendarCard } from "../CalendarCard/CalendarCard";
import { Calendar, CalendarMode } from "../Calendar/Calendar";
import { Heading, Text, Alert } from "@puregym/ui";
import { MaxWidthContainer } from "../MaxWidthContainer";
import { CalendarDayListHeader } from "../CalendarDayListHeader/CalendarDayListHeader";
import { useAuthentication } from "src/hooks/useAuthentication";
import { useTimetable } from "./context";
import {
  setClassesStarted,
  setClassesSucceeded,
  setCalendarMode,
  setNameFilter,
  setTypeFilter,
  setTimeRangeFilter,
  selectWeek,
  setClassesFailed,
  setMemberDetailsSucceded,
  setMemberDetailsFailed,
  selectGym,
  classBooked,
  classCancelled,
  redirectToUrl,
  openModal,
  closeModal,
  deselectClass,
  deselectGym,
} from "./store";
import { SelectedClassTypeFilter, SelectedClassNameFilter } from "../ClassTypeFilter/ClassTypeFilter";
import { CalendarModeSelector } from "../CalendarModeSelector/CalendarModeSelector";
import { WeekSelector } from "../WeekSelector/WeekSelector";
import { SelectedGymFilter } from "../GymFilter/GymFilter";
import { getClassNames, getCalendarActivities, getCalendarActivityDates, getFilteredClasses } from "./selectors";
import { TimeRange, Week } from "src/models/time";
import { BookingModal } from "../BookingModal/BookingModal";
import { GenderRestriction, Gym } from "src/models/gym";
import { hasSelectedClassIdInUrl, getSelectedClassIdInUrl } from "./selectedClassUrlHelper";
import { TimetableFilters } from "./TimetableFilters";
import { ok } from "neverthrow";
import * as cache from "../../utils/cache";

const Container = styled.section`
  -webkit-font-smoothing: antialiased;
`;

const Header = styled.div`
  padding-top: ${({ theme }) => theme.spacing.doubleSpacing};
  width: 100%;
  max-width: ${({ theme }) => theme.page.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

const CalendarContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.doubleSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.doubleSpacing};
  background-color: ${({ theme }) => theme.colors.accents.muted};
  transition: background-color ${({ theme }) => theme.transitionsMs.default};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WeekSelectorRow = styled(MaxWidthContainer)`
  margin-bottom: ${({ theme }) => theme.spacing.doubleSpacing};
`;

const DesktopOnly = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: initial;
  }
`;

const ApiErrorAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.doubleSpacing};
`;

const AlertContent = styled.p`
  margin: ${({ theme }) => theme.spacing.halfSpacing} 0;
`;

const excludedGenders: Record<GenderRestriction, Gender[]> = {
  [GenderRestriction.NoRestriction]: [],
  [GenderRestriction.FemaleOnly]: [Gender.Male],
  [GenderRestriction.MaleOnly]: [Gender.Female],
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {};

export interface TimetableProps {
  allGyms?: Gym[];
  introductionText?: string | null;
  onGymSelected?: (gymId: number) => void;
}

export const Timetable: React.FC<TimetableProps> = ({
  allGyms = [],
  introductionText,
  onGymSelected = noop,
}: TimetableProps) => {
  const { dispatch, ...state } = useTimetable();
  const {
    labels,
    isLoading,
    calendarMode,
    timetableType,
    currentWeek,
    getClassesError,
    getMemberDetailsError,
    gym,
    isInitialized,
    isModalOpen,
    currentClass,
    member,
  } = state;

  const ref = useRef<HTMLDivElement>(null);

  const isMounted = () => ref.current !== null;

  const isWeekView = calendarMode === "WeekView";
  const isGymAccessSlotBooking = timetableType === "gymAccessSlot";

  const { isAuthenticated, sessionId } = useAuthentication();

  const showApiError = getClassesError !== null || getMemberDetailsError !== null;

  const showWeekSelector = isWeekView;

  const showGymFilter = !!allGyms.length;
  const waitingForMemberDetails = showGymFilter && !member;

  const classNames = useMemo(() => getClassNames(state), [state.scheduledClasses]);

  const filteredClasses = useMemo(
    () => getFilteredClasses(state),
    [state.filters.className, state.filters.classType, state.filters.timeRange, state.scheduledClasses]
  );

  const activityDates = useMemo(
    () => getCalendarActivityDates(state),
    [state.scheduledClasses, state.calendarMode, state.currentWeek]
  );

  const activities = useMemo(() => getCalendarActivities(filteredClasses), [filteredClasses]);

  const switchCalendarMode = useCallback(
    (selectedCalendarMode: CalendarMode) => dispatch(setCalendarMode(selectedCalendarMode)),
    []
  );

  const handleTimeRangeSelected = (filter: TimeRange | null): void => {
    dispatch(setTimeRangeFilter(filter));
  };

  const handleWeekSelected = useCallback((week: Week): void => dispatch(selectWeek(week)), []);

  const handleGymSelected = useCallback((filter: SelectedGymFilter): void => {
    const selectedGym = allGyms.find(g => g.id === filter.gymId);

    if (selectedGym) {
      onGymSelected(selectedGym.id);
      dispatch(selectGym(selectedGym));
    } else {
      dispatch(deselectGym(Number(filter.gymId)));
    }
  }, []);

  const handleClassNameSelected = useCallback(
    (className: SelectedClassNameFilter) => dispatch(setNameFilter(className)),
    []
  );

  const handleClassTypeSelected = useCallback(
    (filter: SelectedClassTypeFilter | null) => dispatch(setTypeFilter(filter)),
    []
  );

  const getCachedMemberDetails = async (): ReturnType<typeof getMemberDetails> => {
    const key = "member";

    const cachedMemberDetails = cache.get<Member>(key, sessionId);

    if (cachedMemberDetails) {
      return ok(cachedMemberDetails);
    }

    const response = await getMemberDetails();

    response.map(memberDetails => {
      cache.save<Member>(key, memberDetails, sessionId);
    });

    return response;
  };

  const loadMemberDetailsAsync = async () => {
    const response = await getCachedMemberDetails();

    response.match(
      memberDetails => {
        if (isMounted()) {
          dispatch(setMemberDetailsSucceded(memberDetails));
          if (!gym) {
            handleGymSelected({ gymId: memberDetails.primaryGymId });
          }
        }
      },
      error => isMounted() && dispatch(setMemberDetailsFailed(error))
    );
  };

  useEffect(() => {
    if (isAuthenticated && waitingForMemberDetails) {
      loadMemberDetailsAsync();
    }
  }, []);

  const loadClassesAsync = async () => {
    if (!gym?.id || gym?.id < 0) return;

    dispatch(setClassesStarted());

    const response = isGymAccessSlotBooking ? await getGymAccessSlots(gym?.id) : await getScheduledClasses(gym?.id);

    response.match(
      result => isMounted() && dispatch(setClassesSucceeded(result)),
      error => !isRequestCancelled(error) && isMounted() && dispatch(setClassesFailed(error))
    );
  };

  useEffect(() => {
    loadClassesAsync();
  }, [gym?.id]);

  useEffect(() => {
    if (isInitialized && hasSelectedClassIdInUrl()) {
      const id = getSelectedClassIdInUrl();
      const selectedClass = id && state.scheduledClasses.find(s => s.id.equals(id));
      if (selectedClass) {
        dispatch(openModal(selectedClass));
      } else {
        dispatch(deselectClass());
      }
    }
  }, [isInitialized]);

  const renderClass = (c: ScheduledClass) => {
    const showLoadingSpinner = waitingForMemberDetails || (isLoading && c.id !== currentClass?.id);

    return (
      <div
        key={c.id.toString()}
        role="button"
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            dispatch(openModal(c));
          }
        }}
        onClick={() => {
          dispatch(openModal(c));
        }}
      >
        <CalendarCard
          scheduledClass={c}
          variant={timetableType}
          isLoading={showLoadingSpinner}
          isWeekView={isWeekView}
          isAuthenticated={isAuthenticated}
        />
      </div>
    );
  };

  const renderCalendarDayListHeader = () =>
    isWeekView ? null : <CalendarDayListHeader variant={timetableType} showClassInfo={isAuthenticated} />;

  const renderIntroductionText = () =>
    introductionText ? (
      <Text
        as="div"
        dangerouslySetInnerHTML={{
          __html: introductionText,
        }}
      ></Text>
    ) : null;

  const renderApiErrorAlert = () =>
    showApiError ? (
      <ApiErrorAlert variant="error" role="alert">
        <AlertContent>{labels.timetable.genericApiError}</AlertContent>
      </ApiErrorAlert>
    ) : null;

  const availableGyms =
    gym && member
      ? allGyms.filter(g => {
          const restriction = g.genderRestriction || GenderRestriction.NoRestriction;
          const exclude = restriction in excludedGenders && excludedGenders[restriction].includes(member.gender);
          return !exclude;
        })
      : [];

  return (
    <Container data-testid="timetable" ref={ref}>
      {currentClass && gym && (
        <BookingModal
          scheduledClass={currentClass}
          gym={gym}
          timetableType={timetableType}
          isOpen={isModalOpen}
          onClose={() => {
            dispatch(closeModal());
          }}
          bookClass={async (classId: EntityId) => {
            const result = await bookClass(classId);

            result.map(bookingId => {
              dispatch(classBooked(classId, bookingId));
            });

            loadClassesAsync();

            return result;
          }}
          cancelClass={async (classId: EntityId, bookingId: BookingId) => {
            const result = await cancelBooking(bookingId);

            result.map(() => {
              dispatch(classCancelled(classId));
            });

            loadClassesAsync();

            return result;
          }}
          redirectToUrl={(url: string) => {
            dispatch(redirectToUrl(url));
          }}
        ></BookingModal>
      )}
      <MaxWidthContainer>
        <Header>
          {renderApiErrorAlert()}
          <Row>
            <Heading variant="beta">{labels.timetable.pageHeading}</Heading>
            <DesktopOnly>
              <CalendarModeSelector activeMode={calendarMode} onCalendarModeSelected={switchCalendarMode} />
            </DesktopOnly>
          </Row>

          {renderIntroductionText()}

          <TimetableFilters
            classNames={classNames}
            timetableType={timetableType}
            showGymFilter={showGymFilter}
            gyms={availableGyms}
            selectedGymId={gym?.id}
            onGymSelected={handleGymSelected}
            onClassNameSelected={handleClassNameSelected}
            onClassTypeSelected={handleClassTypeSelected}
            onTimeRangeSelected={handleTimeRangeSelected}
            member={member}
          />
        </Header>
      </MaxWidthContainer>
      <CalendarContainer aria-busy={isLoading} data-is-initialized={isInitialized && !isLoading}>
        {showWeekSelector && (
          <WeekSelectorRow>
            <WeekSelector currentWeek={currentWeek} onWeekSelected={handleWeekSelected} />
          </WeekSelectorRow>
        )}
        <Calendar
          mode={calendarMode}
          activities={activities}
          activityDates={activityDates}
          renderActivity={renderClass}
          renderCalendarDayListHeader={renderCalendarDayListHeader}
          labels={{
            noBookableActivitiesOnThisDay: labels.timetable.noBookableActivitiesOnThisDay,
            loading: labels.shared.core.loading,
          }}
          isInitialized={isInitialized}
        />
      </CalendarContainer>
    </Container>
  );
};
