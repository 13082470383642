import styled from "styled-components";

export const ModalContent = styled.div.attrs({ "data-testid": "modal-content" })`
  font-size: ${props => props.theme.fontSizes.small};
  margin: 2px;
  min-width: ${props => `calc(100vw - 4 * ${props.theme.spacing.baseSpacing})`};

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: auto;
  }
`;

export enum BookingAction {
  Book = "book",
  JoinWaitingList = "joinWaitingList",
  Cancel = "cancel",
  LeaveWaitingList = "leaveWaitingList",
}

export enum BookingResult {
  Success = "success",
  Failure = "error",
}

export enum BookingButtonType {
  NotSet = "notSet",

  BookClass = "bookClass",
  BookInduction = "bookInduction",
  BookGymAccessSlot = "bookGymAccessSlot",

  JoinClassWaitingList = "joinClassWaitingList",
  JoinInductionWaitingList = "joinInductionWaitingList",
  JoinGymAccessSlotWaitingList = "joinGymAccessSlotWaitingList",

  CancelClass = "cancelClass",
  CancelGymAccessSlot = "cancelGymAccessSlot",
  LeaveWaitingList = "leaveWaitingList",
}
