import { Dayjs } from "dayjs";
import { groupBy } from "../../utils/arrayUtils";
import { getTime } from "../../utils/dateUtils";
import { includes } from "../../utils/timeUtils";
import { TimetableState } from "./store";
import { ScheduledClass } from "../../bookingsApi/models";
import { Time } from "../../models/time";
import { CalendarActivities, IsoDateString } from "../Calendar/Calendar";
import { getCalendarDays } from "./getCalendarDays";

const equals = (c1: string, c2: string, negate = false): boolean => (negate ? c1 !== c2 : c1 === c2);

export const getFilteredClasses = ({
  filters,
  scheduledClasses,
}: Pick<TimetableState, "filters" | "scheduledClasses">): ScheduledClass[] => {
  const classNameMatches = (c: ScheduledClass): boolean => !filters.className || c.name === filters.className.className;

  const classTypeMatches = (c: ScheduledClass): boolean =>
    !filters.classType || equals(c.classType, filters.classType.classType, filters.classType.negate);

  const timeRangeMatches = (c: ScheduledClass): boolean => {
    if (!filters.timeRange) return true;
    const startTime: Time = getTime(c.startDateTime);
    return includes(filters.timeRange, startTime);
  };

  const filterRules = [
    { name: "classNameMatches", evaluate: classNameMatches },
    { name: "classTypeMatches", evaluate: classTypeMatches },
    { name: "timeRangeMatches", evaluate: timeRangeMatches },
  ];

  const filteredClasses = scheduledClasses.filter((c: ScheduledClass) => filterRules.every(rule => rule.evaluate(c)));

  return filteredClasses;
};

export const getClassNames = ({ scheduledClasses }: Pick<TimetableState, "scheduledClasses">): string[] => {
  const uniqueClassNames: Set<string> = new Set(scheduledClasses.map((c: ScheduledClass) => c.name));
  const classNames: string[] = [...uniqueClassNames];

  return classNames;
};

const getDate = (scheduledClass: ScheduledClass): Dayjs => scheduledClass.startDateTime.startOf("day");

const groupActivitiesByDay = (scheduledClasses: ScheduledClass[]) =>
  groupBy(scheduledClasses, c => getDate(c).toIsoDateString());

export const getCalendarActivities = (
  filteredActivities: ScheduledClass[]
): Map<IsoDateString, CalendarActivities<ScheduledClass>> => {
  const classesPerDay = groupBy(filteredActivities, c => getDate(c).toIsoDateString());

  const calendarEntries = Object.entries(classesPerDay).map(
    ([isoDateString, scheduledClasses]: [IsoDateString, ScheduledClass[]]): [
      IsoDateString,
      CalendarActivities<ScheduledClass>
    ] => [
      isoDateString,
      {
        date: getDate(scheduledClasses[0]),
        activities: scheduledClasses,
      },
    ]
  );

  const activities = new Map<IsoDateString, CalendarActivities<ScheduledClass>>(calendarEntries);
  return activities;
};

export const getCalendarActivityDates = ({
  scheduledClasses,
  calendarMode,
  currentWeek,
}: Pick<TimetableState, "scheduledClasses" | "calendarMode" | "currentWeek">): Dayjs[] => {
  const classesPerDay = groupActivitiesByDay(scheduledClasses);
  const activityDates = Object.values(classesPerDay).map(classes => getDate(classes[0]));

  const calendarDays: Dayjs[] = getCalendarDays(activityDates, calendarMode === "WeekView", currentWeek);

  return calendarDays;
};
