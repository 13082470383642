import { ActivityType, ClassTypes } from "src/bookingsApi/models";
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";
import { ok, err } from "neverthrow";
import dayjs from "dayjs";
import { replaceUrlTokensWithQueryParams } from "./interceptors/replaceUrlTokensWithQueryParams";

import {
  BookingId,
  EntityId,
  ScheduledClass,
  ApiError,
  ApiResult,
  ScheduledClassJson,
  GetActivitiesJsonResponse,
  GymAccessSlotJson,
  ApiErrorJson,
  GymAccessSlotType,
  GetBookingsJsonResponse,
  BookedClass,
  WellKnownBookingRuleErrorMessageIds,
} from "./models";
import { urls } from "./bookingsApiUrls";

export * from "./models";

const apiClient = axios.create();

let isApiConfigSet = false;
export let apiConfig: Readonly<ApiConfig>;

export interface ApiConfig {
  locale: string;
  brandHttpHeader: string;
  bookingsApiBaseUrl?: string;
  getAccessToken?: () => string;
}

class ApiConfigError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export const updateConfig = (config: ApiConfig): void => {
  apiClient.defaults.baseURL = config.bookingsApiBaseUrl;
  apiClient.defaults.headers.common["Accept-Language"] = config.locale;
  apiClient.defaults.headers.common["X-PureBrand"] = config.brandHttpHeader;
  apiClient.defaults.timeout = 30000;

  isApiConfigSet = true;
  apiConfig = config;
};

apiClient.interceptors.request.use(config => {
  if (!isApiConfigSet) throw new ApiConfigError("Bookings API config not set");
  return config;
});

apiClient.interceptors.request.use(replaceUrlTokensWithQueryParams);

apiClient.interceptors.request.use(config => {
  const token = typeof apiConfig?.getAccessToken === "function" && apiConfig.getAccessToken();

  config.withCredentials = !token;

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

const handleError = <T>(error: AxiosError<ApiErrorJson> | ApiConfigError): ApiResult<T> => {
  if (error instanceof ApiConfigError) throw error;

  const { message, response } = error;
  const { status: statusCode, data } = response || {};
  const { errors = [] } = data || {};

  if (statusCode === 401 && !errors.length) {
    errors.push({
      errorCode: WellKnownBookingRuleErrorMessageIds.MemberMustBeLoggedIn,
      message: "You must be logged in",
    });
  }

  return err<T, ApiError>({
    message,
    statusCode,
    errors,
  } as ApiError);
};

const canelledRequestErrorCode = "CANCELLED";

export const isRequestCancelled = (error: ApiError): boolean => error?.message === canelledRequestErrorCode;

const handleCancelledRequest = <T>(): ApiResult<T> => {
  return err<T, ApiError>({
    message: canelledRequestErrorCode,
    statusCode: 0,
    errors: [],
  });
};

const padUtcOffset = (dateTime: string): string =>
  /T\d{2}:\d{2}:\d{2}[+-]\d{2}$/.test(dateTime) ? dateTime + "00" : dateTime;

const mapScheduledClassJson = <T extends ScheduledClassJson>(scheduledClass: T): ScheduledClass => {
  const { classCapacity, bookedCount, waitingListCapacity, waitingListCount, bookingStatus, bookingErrors, ...rest } =
    scheduledClass;

  const dateTime = padUtcOffset(scheduledClass.startDateTime.dateTime);
  const timeZone = scheduledClass.startDateTime.timeZone;

  return {
    ...rest,
    id: new EntityId(scheduledClass.id),
    bookingId: scheduledClass.bookingId ? new BookingId(scheduledClass.bookingId) : undefined,
    startDateTime: dayjs(dateTime).tz(timeZone),
    remainingCapacity: classCapacity - bookedCount,
    remainingWaitingListCapacity: waitingListCapacity - waitingListCount,
    reason: bookingStatus,
    bookingErrors: bookingErrors || [],
    classType: mapClassType(scheduledClass.classType),
  };
};

const mapClassType = (classType: string): ActivityType => {
  if (classType.includes(ClassTypes.digital)) return ClassTypes.digital;
  if (classType.includes(ClassTypes.induction)) return ClassTypes.induction;
  if (classType.includes(GymAccessSlotType)) return GymAccessSlotType;

  return ClassTypes.standard;
};

const mapGymAccessSlotJson = (gymAccessSlot: GymAccessSlotJson): ScheduledClass =>
  mapScheduledClassJson({
    ...gymAccessSlot,
    classType: GymAccessSlotType,
    instructorName: "",
  });

let classesCancelTokenSource: CancelTokenSource | null = null;

const handleGetActivitiesPromise = async <TActivityType extends ScheduledClassJson | GymAccessSlotJson>(
  promise: Promise<AxiosResponse<GetActivitiesJsonResponse<TActivityType>>>,
  mapResults: (scheduledClass: TActivityType) => ScheduledClass
): Promise<ApiResult<ScheduledClass[]>> => {
  try {
    const response = await promise;

    classesCancelTokenSource = null;

    const list = response.data.activities || [];
    return ok(list.map(mapResults));
  } catch (error: any) {
    if (axios.isCancel(error)) {
      return handleCancelledRequest();
    } else {
      return handleError(error);
    }
  }
};

const getActivities = async <TActivityType extends ScheduledClassJson | GymAccessSlotJson>(
  gymId: number,
  url: string,
  mapResults: (activity: TActivityType) => ScheduledClass
): Promise<ApiResult<ScheduledClass[]>> => {
  if (classesCancelTokenSource) {
    classesCancelTokenSource.cancel("Cancel previous request");
  }

  classesCancelTokenSource = axios.CancelToken.source();

  const promise = apiClient.get<GetActivitiesJsonResponse<TActivityType>>(url, {
    params: {
      gymId,
    },
    cancelToken: classesCancelTokenSource.token,
  });

  return handleGetActivitiesPromise(promise, mapResults);
};

export const getScheduledClasses = async (gymId: number): Promise<ApiResult<ScheduledClass[]>> =>
  getActivities<ScheduledClassJson>(gymId, urls.scheduledClasses, mapScheduledClassJson);

export const getGymAccessSlots = async (gymId: number): Promise<ApiResult<ScheduledClass[]>> =>
  getActivities<ScheduledClassJson>(gymId, urls.gymAccessSlots, mapGymAccessSlotJson);

export type BookClassRespone = {
  bookingId: string;
};

export const bookClass = async (entityId: EntityId): Promise<ApiResult<BookingId>> => {
  try {
    const response = await apiClient.post<BookClassRespone>(urls.bookClass, { classId: entityId.value });
    return ok(new BookingId(response.data.bookingId));
  } catch (error: any) {
    return handleError(error);
  }
};

export const cancelBooking = async (bookingId: BookingId): Promise<ApiResult<undefined>> => {
  try {
    await apiClient.delete(urls.cancelBooking, {
      params: {
        bookingId: bookingId.toString(),
      },
    });
    return ok(undefined);
  } catch (error: any) {
    return handleError(error);
  }
};

export type GetBookingsResponse = {
  bookings: BookedClass[];
};

export const getBookings = async (): Promise<ApiResult<GetBookingsResponse>> => {
  try {
    const response = await apiClient.get<GetBookingsJsonResponse>(urls.bookings);

    const list = response.data.bookings || [];
    return ok({
      bookings: list.map(booking => {
        const bookedClass = mapScheduledClassJson(booking) as BookedClass;
        bookedClass.gym = booking.gym;
        return bookedClass;
      }),
    });
  } catch (error: any) {
    return handleError(error);
  }
};

export enum Gender {
  NotSupplied = "NotSupplied",
  Male = "Male",
  Female = "Female",
  Other = "Other",
}

export type GetMemberDetailsResponse = {
  primaryGymId: number;
  secondaryGymId?: number;
  gender: Gender;
};

export type Member = GetMemberDetailsResponse;

export const getMemberDetails = async (): Promise<ApiResult<Member>> => {
  try {
    const response = await apiClient.get<GetMemberDetailsResponse>(urls.memberDetails);
    return ok(response.data);
  } catch (error: any) {
    return handleError(error);
  }
};
