import { Dayjs } from "dayjs";
import { iOS } from "src/utils/browserUtils";

export type CalendarEvent = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  title: string;
  description: string;
  location: string;
  organizer: string;
  organizerEmail: string;
  language: string;
};

type CalendarLink = {
  text: string;
  url: string;
};

const atc_url = "//addtocalendar.com/atc/";
const atc_version = "1.5";
const calendars = ["iCalendar", "Google Calendar", "Outlook", "Outlook Online", "Yahoo! Calendar"];

const calendarsUrl: Record<string, string> = {
  iCalendar: "ical",
  "Google Calendar": "google",
  Outlook: "outlook",
  "Outlook Online": "outlookonline",
  "Yahoo! Calendar": "yahoo",
};

const buildQueryString = (event: CalendarEvent): string => {
  const utz = event.startDateTime.utcOffset().toString();

  const eventParameters: Record<string, string> = {
    date_start: event.startDateTime.format("YYYY-MM-DDTHH:mm:ss"),
    date_end: event.endDateTime.format("YYYY-MM-DDTHH:mm:ss"),
    timezone: event.startDateTime.getTimeZoneName(),
    title: event.title,
    description: event.description,
    location: event.location,
    organizer: event.organizer,
    organizer_email: event.organizerEmail,
  };

  const eventUrlParameters = Object.entries(eventParameters).map(
    ([key, value]) => `e[0][${key}]=${encodeURIComponent(value)}`
  );

  const url_paramteres = ["utz=" + utz, "uln=" + event.language, "vjs=" + atc_version].concat(eventUrlParameters);

  return url_paramteres.join("&");
};

export const buildCalendarUrls = (event: CalendarEvent): CalendarLink[] => {
  const queryString = buildQueryString(event);

  const links = calendars.map(calendar => {
    const calendarId = calendarsUrl[calendar];

    const protocol = calendarId === "ical" && iOS() ? "webcal:" : "https:";

    const eventUrl = `${protocol}${atc_url}${calendarId}?${queryString}`;

    return {
      text: calendar,
      url: eventUrl,
    };
  });

  return links;
};
