import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Settings } from "../localization/localizedSettings";
import { Translations } from "../localization/translations";
import { RecursivePartial } from "../declarations/recursivePartial";
import { getDayjsLocale } from "../utils/dayjs/getDayjsLocale";

interface LocalizationData {
  locale: string;
  dayjs: Dayjs;
  formatDate: (date: Dayjs, template?: string | undefined) => string;
  formatDateWithPattern: <T extends RecursivePartial<Settings>>(
    date: Dayjs,
    templateFn: (settings: T) => string,
    defaultTemplate?: string | undefined
  ) => string;
  getLabels: <T extends RecursivePartial<Translations>>() => T;
  getSettings: <T extends RecursivePartial<Settings>>() => T;
}

export type AllLabels = {
  bookings: Omit<Translations, "shared">;
  shared: Translations["shared"];
};

const LocalizationContext = React.createContext<LocalizationData | null>(null);

export interface LocalizationProviderProps {
  locale: string;
  labels: AllLabels;
  settings: Settings;
  children: React.ReactNode;
}

export const LocalizationProvider = ({ locale, labels, settings, children }: LocalizationProviderProps) => {
  const dayjsLocale = getDayjsLocale(locale);

  require(`dayjs/locale/${dayjsLocale}.js`);

  const dayFn: Dayjs = dayjs().locale(dayjsLocale);

  const getLocalizedDate = (date: Dayjs) => (date.locale() === dayjsLocale ? date : date.locale(dayjsLocale));
  const formatDate = (date: Dayjs, template?: string | undefined): string => getLocalizedDate(date).format(template);

  const allLabels: Translations = {
    ...labels.bookings,
    shared: labels.shared,
  };

  const localizationData: LocalizationData = {
    locale,
    formatDate,
    dayjs: dayFn,
    getLabels: <T extends RecursivePartial<Translations>>() => allLabels as T,
    getSettings: <T extends RecursivePartial<Settings>>() => settings as T,
    formatDateWithPattern: <T extends RecursivePartial<Settings>>(
      date: Dayjs,
      templateFn: (settings: T) => string,
      defaultTemplate?: string | undefined
    ) => {
      const template = templateFn(settings as T);
      return formatDate(date, template || defaultTemplate);
    },
  };

  return <LocalizationContext.Provider value={localizationData}>{children}</LocalizationContext.Provider>;
};

export function useLocale(): LocalizationData {
  const data = React.useContext(LocalizationContext);

  if (data === null) throw new Error("Localization Context not initialized");

  return data;
}
