import { useState } from "react";
import Cookies from "js-cookie";
import { COOKIES } from "@puregym/ui";

export const useAuthentication = () => {
  const [authenticationCookie = ""] = useState(() => Cookies.get(COOKIES.AUTH_HINT));

  return {
    isAuthenticated: Boolean(authenticationCookie),
    sessionId: authenticationCookie,
  };
};
