import React from "react";
import styled from "styled-components";
import { TabList, Tabs, Tab } from "@puregym/ui";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { TabLink } from "src/components/TimetableTabs/TabLink";
import { TimetableType } from "src/models/timetableType";

const StyledTabs = styled(Tabs)`
  margin-top: ${({ theme }) => theme.spacing.doubleSpacing};
`;

export type NavigationState = {
  selectedGymId?: number;
};

type TimetableTabsProps = {
  timetableType: TimetableType;
  classesTab: TabData;
  gymAccessSlotsTab: TabData;
  navigationState: NavigationState;
};

type TabData = {
  url: string;
  label: string;
};

export const TimetableTabs: React.FC<TimetableTabsProps> = ({
  timetableType,
  classesTab,
  gymAccessSlotsTab,
  navigationState,
}: TimetableTabsProps) => {
  const isClassBooking = timetableType === "scheduledClass";

  const renderTabs = () => {
    const tab1Props = isClassBooking ? {} : { as: TabLink, to: classesTab.url, state: navigationState };
    const tab2Props = isClassBooking ? { as: TabLink, to: gymAccessSlotsTab.url, state: navigationState } : {};

    return [
      <Tab key="tab1" {...tab1Props}>
        {classesTab.label}
      </Tab>,
      <Tab key="tab2" {...tab2Props}>
        {gymAccessSlotsTab.label}
      </Tab>,
    ];
  };

  return (
    <MaxWidthContainer>
      <StyledTabs defaultIndex={0}>
        <TabList>{renderTabs()}</TabList>
      </StyledTabs>
    </MaxWidthContainer>
  );
};
