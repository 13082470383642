export enum Start {
  FromZero = 0,
  FromOne = 1,
}

export class Range {
  static of(length: number, start = Start.FromZero): number[] {
    return [...Array(length).keys()].map(index => index + start);
  }
}
