import { css } from "styled-components";
import { TimetableType } from "src/models/timetableType";

const cellPaddingHorizontal = "15px";
const rowPaddingHorizontal = cellPaddingHorizontal;

const getColumnStyles = (columnWidths: number[]) => {
  const classNameStyles = css`
    padding-inline-end: ${cellPaddingHorizontal};
    flex: ${columnWidths[0]};
  `;

  const timeStyles = css`
    padding-inline-end: ${cellPaddingHorizontal};
    flex: ${columnWidths[1]};
  `;

  const studioStyles = css`
    padding-inline-end: ${cellPaddingHorizontal};
    flex: ${columnWidths[2]};
  `;

  const instructorStyles = css`
    padding-inline-end: ${cellPaddingHorizontal};
    flex: ${columnWidths[3]};
  `;

  const statusStyles = css`
    flex: ${columnWidths[4]};
    min-width: 100px;
    padding-inline-start: ${cellPaddingHorizontal};
    padding-inline-end: ${rowPaddingHorizontal};
    text-align: center;
  `;

  return {
    className: classNameStyles,
    time: timeStyles,
    studio: studioStyles,
    instructor: instructorStyles,
    status: statusStyles,
  };
};

const statusBorderStyles = css`
  border-inline-start: 1px dotted ${({ theme }) => theme.colors.accents.light};
`;

const scheduledClassColumnWidths = [2, 1, 1, 1, 0];
const gymAccessSlotColumnWidths = [1, 1, 0, 0, 0];

export type ColumnStyles = ReturnType<typeof getColumnStyles>;

export const listViewStyles: {
  rowPaddingHorizontal: string;
  columns: Record<TimetableType, ColumnStyles>;
  statusBorder: ReturnType<typeof css>;
} = {
  rowPaddingHorizontal,
  columns: {
    gymAccessSlot: getColumnStyles(gymAccessSlotColumnWidths),
    scheduledClass: getColumnStyles(scheduledClassColumnWidths),
  },
  statusBorder: statusBorderStyles,
};
