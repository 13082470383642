export const replaceTokens: (
  template: string,
  interpolations: Record<string, string | number>,
  throwErrors?: boolean
) => string = (template, interpolations, throwErrors = true) =>
  template.replace(/\{\s*(\w+)\s*\}/g, (marker: string, token: string): string => {
    if (throwErrors && !Object.prototype.hasOwnProperty.call(interpolations, token)) {
      throw new Error(`Missing token value for '${marker}'`);
    }

    const tokenValue = token in interpolations ? interpolations[token].toString() : null;
    return tokenValue || marker;
  });
