const isEmpty = (params: URLSearchParams): boolean => [...params.keys()].length === 0;

const updateQueryString = (modifySearchParams: (params: URLSearchParams) => void): void => {
  const { pathname, search } = window.location;

  const searchParams = new URLSearchParams(search);
  modifySearchParams(searchParams);
  const queryString = isEmpty(searchParams) ? "" : `?${searchParams}`;

  const newurl = `${pathname}${queryString}`;

  window.history.pushState({ path: newurl }, "", newurl);
};

export const insertUrlParameter = (key: string, value: string): void =>
  updateQueryString(searchParams => searchParams.set(key, value));

export const removeUrlParameter = (key: string): void => updateQueryString(searchParams => searchParams.delete(key));

const getSearchParams = (): URLSearchParams => new URLSearchParams(window.location.search);

export const getParameter = (key: string): string | null => getSearchParams().get(key);

export const hasParameter = (key: string): boolean => getSearchParams().has(key);
