import React from "react";
import { BookingError, WellKnownBookingRuleErrorMessageIds } from "src/bookingsApi/models";
import styled from "styled-components";
import { useLocale } from "src/providers/LocalizationProvider";
import { darken } from "polished";
import { replaceTokens } from "src/utils/stringUtils";

const Container = styled.ul`
  padding: 0;
  padding-top: ${props => props.theme.spacing.baseSpacing};
  background-color: ${props => props.theme.colors.alerts.error};
  padding-inline-start: ${props => props.theme.spacing.baseSpacing};
  font-weight: ${props => props.theme.fontWeights.heading};
  color: ${({ theme }) => darken(0.7, theme.colors.alerts.error)};
`;

const StatusMessage = styled.p`
  margin: 0;
  padding-bottom: ${props => props.theme.spacing.baseSpacing};
`;

const Item = styled.li`
  list-style: none;
  padding-bottom: ${props => props.theme.spacing.baseSpacing};
  padding-inline-start: 0;
`;

const Message = styled.strong`
  line-height: ${props => props.theme.lineHeights.heading};
  color: ${({ theme }) => darken(0.7, theme.colors.alerts.error)};
  font-weight: ${props => props.theme.fontWeights.body};
`;

type CamelCase<T> = T extends `${infer FirstLetter}${infer Rest}` ? `${Lowercase<FirstLetter>}${Rest}` : never;

export type Translations = {
  bookingErrors: {
    [P in WellKnownBookingRuleErrorMessageIds as CamelCase<P>]: string;
  };
};

type BookingErrorsProps = {
  bookingErrors: BookingError[];
  statusMessage?: string;
  messageAfter?: string;
};

const toLowerCaseFirstChar = (text: string): string => text.substr(0, 1).toLowerCase() + text.substr(1);

export const BookingErrors: React.FC<BookingErrorsProps> = ({
  bookingErrors = [],
  statusMessage = "",
  messageAfter = "",
}: BookingErrorsProps) => {
  if (!bookingErrors.length && !statusMessage) return null;

  const { getLabels } = useLocale();
  const { bookingErrors: labels } = getLabels<Translations>();

  const messages = labels as Record<string, string>;

  const getErrorMessage = (error: BookingError) => {
    const errorCodeCamelCase = toLowerCaseFirstChar(error.errorCode);

    const message = messages[errorCodeCamelCase] || error.errorCode;

    if (!error.extraErrorInfo || !Object.keys(error.extraErrorInfo).length) {
      return message;
    }

    return replaceTokens(message, error.extraErrorInfo);
  };

  return (
    <Container role="alert">
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}

      {bookingErrors.map(error => {
        return (
          <Item key={error.errorCode}>
            <Message data-errorcode={error.errorCode}>{getErrorMessage(error)}</Message>
          </Item>
        );
      })}

      {messageAfter && <p>{messageAfter}</p>}
    </Container>
  );
};
