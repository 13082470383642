import React from "react";
import styled, { css } from "styled-components";
import { Gym } from "src/models/gym";
import { TimetableType } from "src/models/timetableType";
import {
  ClassNameSelectedCallback,
  ClassTypeFilter,
  ClassTypeSelectedCallback,
} from "../ClassTypeFilter/ClassTypeFilter";
import { GymFilter, GymSelectedCallback, LoadingGymFilter } from "../GymFilter/GymFilter";
import { TimeRangeFilter, TimeRangeSelectedCallback } from "../TimeRangeFilter/TimeRangeFilter";
import { GetMemberDetailsResponse } from "src/bookingsApi/bookingsApi";

const FilterRow = styled.div<{ showGymFilter: boolean }>`
  padding-top: ${({ theme }) => theme.spacing.baseSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.baseSpacing};

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: ${({ theme }) => theme.spacing.halfSpacing};

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: ${({ showGymFilter }) =>
      (showGymFilter && css`2fr minmax(250px, auto) minmax(250px, auto)`) || css`repeat(3, 1fr)`};
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-column-gap: ${({ theme }) => theme.spacing.baseSpacing};
  }
`;

type TimetableFiltersProps = {
  showGymFilter: boolean;
  timetableType: TimetableType;
  gyms: Gym[];
  classNames: string[];
  selectedGymId?: number;
  member: GetMemberDetailsResponse | undefined;
  onClassNameSelected: ClassNameSelectedCallback;
  onClassTypeSelected: ClassTypeSelectedCallback;
  onTimeRangeSelected: TimeRangeSelectedCallback;
  onGymSelected: GymSelectedCallback;
};

export const TimetableFilters: React.FC<TimetableFiltersProps> = ({
  showGymFilter,
  timetableType,
  gyms,
  classNames,
  selectedGymId,
  member,
  onClassNameSelected,
  onClassTypeSelected,
  onTimeRangeSelected,
  onGymSelected,
}: TimetableFiltersProps) => {
  const isGymAccessSlotBooking = timetableType === "gymAccessSlot";
  const showClassTypeFilter = !isGymAccessSlotBooking;

  const renderGymFilter = () => {
    if (!selectedGymId) {
      return <LoadingGymFilter />;
    }

    return <GymFilter gyms={gyms} selectedGymId={selectedGymId} member={member} onGymSelected={onGymSelected} />;
  };

  return (
    <FilterRow showGymFilter={showGymFilter} data-testid="filters">
      {showGymFilter && renderGymFilter()}

      {showClassTypeFilter && (
        <ClassTypeFilter
          classNames={classNames}
          onClassNameSelected={onClassNameSelected}
          onClassTypeSelected={onClassTypeSelected}
        />
      )}
      <TimeRangeFilter onTimeRangeSelected={onTimeRangeSelected} />
    </FilterRow>
  );
};
