import { useAuthentication } from "./useAuthentication";
import { useConfig } from "src/providers/ConfigProvider";

type MemberType = {
  isAnonymous: boolean;
};

export const useMemberType = (): MemberType => {
  const { isAuthenticated } = useAuthentication();
  const { allowAnonymousUsers } = useConfig();

  return {
    isAnonymous: allowAnonymousUsers && !isAuthenticated,
  };
};
