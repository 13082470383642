import React from "react";
import { Dayjs } from "dayjs";
import styled, { css } from "styled-components";
import themeFor from "styled-theming";
import { useLocale } from "src/providers/LocalizationProvider";
import { siteHeaderHeight } from "src/styles/sharedStyles";

const Container = styled.section`
  display: contents;

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const Header = styled.h2`
  position: sticky;
  top: ${siteHeaderHeight};

  margin: 0;
  padding: 5px;

  border-top-right-radius: ${({ theme }) => theme.radii.default};
  border-top-left-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme }) => theme.colors.primary.contrastText};

  font-size: ${({ theme }) => theme.fontSizes.standard};
  font-weight: ${({ theme }) => theme.fontWeights.body};

  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.25;
  text-align: center;

  & > span:nth-child(2) {
    margin-inline-start: ${props => props.theme.spacing.quarterSpacing};
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-row: 1;
    flex-direction: column;

    & > span:nth-child(2) {
      margin-inline-start: 0;
    }
  }
`;

const ActivityList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ActivityListItem = styled.li<{ isListView: boolean }>`
  border: 1px solid;
  border-top-width: 0;
  background-color: ${({ theme }) => theme.colors.background};

  &:last-child {
    border-bottom-width: 0;
  }

  ${({ isListView }) =>
    !isListView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        &:last-child {
          border-bottom-width: 1px;
        }
      }
    `}

  border-color: ${({ theme: { colors } }) =>
    themeFor("mode", {
      light: colors.opacities.inverted,
      dark: `${colors.opacities.strong} ${colors.background} ${colors.opacities.strong}`,
    })};

  ${({ isListView }) =>
    isListView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        border-color: ${({ theme: { colors } }) =>
          themeFor("mode", {
            light: colors.opacities.invertedSubtle,
          })};
      }
    `}
`;

const defaultDateFormat = "dddd|DD MMMM";
const defaultAriaDateFormat = "dddd, D MMMM";
const lineSeparator = "|";

export type Translations = {
  calendarDay: {
    today: string;
    tomorrow: string;
  };
};

export type Settings = {
  calendarDay: {
    dateFormat: string;
    accessible: {
      dateFormat: string;
    };
  };
};

const formatHeaderText = (date: Dayjs) => {
  const { getLabels, formatDate, getSettings } = useLocale();
  const { calendarDay: labels } = getLabels<Translations>();

  if (date.isToday()) return labels.today;
  if (date.isTomorrow()) return labels.tomorrow;

  const settings = getSettings<Settings>();

  const pattern = settings.calendarDay.dateFormat || defaultDateFormat;
  const patterns = pattern.split(lineSeparator);

  const lines = patterns.map(p => formatDate(date, p));

  if (lines.length === 1) return lines[0];

  return lines.map((content, index) => <span key={index}>{content}</span>);
};

const getAriaLabel = (date: Dayjs): string | undefined => {
  if (date.isToday() || date.isTomorrow()) return undefined;

  const { formatDateWithPattern: formatDate } = useLocale();
  const getDateFormat = (settings: Settings) => settings.calendarDay.accessible.dateFormat;
  return formatDate(date, getDateFormat, defaultAriaDateFormat);
};

export interface CalendarDayProps {
  date: Dayjs;
  children?: JSX.Element | JSX.Element[];
  isListView: boolean;
  renderCalendarDayListHeader: () => JSX.Element | JSX.Element[] | null;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({
  date,
  children,
  isListView = false,
  renderCalendarDayListHeader: renderActivityListHeader = () => null,
}: CalendarDayProps) => {
  return (
    <Container role="row" tabIndex={0}>
      <Header role="columnheader" aria-label={getAriaLabel(date)}>
        {formatHeaderText(date)}
      </Header>
      {renderActivityListHeader()}
      <ActivityList>
        {React.Children.map(children, child => (
          <ActivityListItem isListView={isListView}>{child}</ActivityListItem>
        ))}
      </ActivityList>
    </Container>
  );
};
