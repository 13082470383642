import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { HLocation } from "@reach/router";
import { Button, ButtonProps, Heading, IconArrowLeft, Theme } from "@puregym/ui";
import Layout from "src/components/Layout";
import { MaxWidthContainer } from "src/components/MaxWidthContainer";
import { Gym } from "src/models/gym";
import { TimetableType } from "src/models/timetableType";
import { Timetable } from "src";
import type { Locale } from "config/types";
import { NavigationState, TimetableTabs } from "src/components/TimetableTabs/TimetableTabs";

import "@reach/tabs/styles.css";

const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding-top: ${({ theme }) => theme.spacing.doubleSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.doubleSpacing};
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.contrastText};
  margin-bottom: 0;
`;

const ButtonContainer = styled(MaxWidthContainer)`
  margin-top: ${({ theme }) => theme.spacing.doubleSpacing};
`;

const DashboardButton = styled(Button)<ButtonProps>`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: ${({ theme }) => theme.fontSizes.standard};
  }
`;

const Icon = styled(IconArrowLeft)`
  html[dir="rtl"] & {
    transform: scaleX(-1);
  }
`;

type PageMeta = {
  meta: {
    pageTitle: string;
  };
};

interface QueryData {
  site: {
    siteMetadata: {
      theme: Theme;
      locales: Locale[];
      memberTimetablePageBaseUrl: string;
      memberGymAccessSlotPageBaseUrl: string;
    };
  };
  gyms: {
    nodes: Gym[];
  };
  labels: PageMeta & {
    membersAreaHeading: string;
    timetableTabs: {
      classes: string;
      gymAccessSlots: string;
    };
    membersDashboardButtonText: string;
  };
  urls: {
    authLogin: string;
    authLogout: string;
    accountMemberDashboard: string;
  };
  flags: {
    gymAccessSlotBookingEnabled: boolean;
  };
}

interface PageContext {
  timetableType: TimetableType;
  urlsByLocale: Partial<Record<Locale, string>>;
}

export interface Props {
  pageContext: PageContext;
  data: QueryData;
  location?: HLocation<NavigationState>;
}

export { Head } from "./head";

const MemberTimetable: React.FC<Props> = ({ pageContext, data, location }) => {
  const { gyms, site, labels, urls, flags } = data;
  const { theme, locales, memberTimetablePageBaseUrl, memberGymAccessSlotPageBaseUrl } = site.siteMetadata;
  const showTabs = flags.gymAccessSlotBookingEnabled;

  const allGyms = gyms.nodes;

  const [selectedGymId, setSelectedGymId] = useState<number | undefined>(location?.state?.selectedGymId);
  const currentGym = allGyms.find(gym => gym.id === selectedGymId);

  const navigationState: NavigationState = {
    selectedGymId,
  };

  return (
    <Layout
      pageTitle={labels.meta.pageTitle}
      theme={theme}
      supportedLocales={locales}
      urlsByLocale={pageContext.urlsByLocale}
      urls={urls}
    >
      <>
        <Header>
          <MaxWidthContainer>
            <StyledHeading variant="beta">{labels.membersAreaHeading}</StyledHeading>
          </MaxWidthContainer>
        </Header>
        <ButtonContainer>
          <DashboardButton as="a" variant="subdued" href={urls.accountMemberDashboard}>
            <Icon aria-hidden />
            <span>{labels.membersDashboardButtonText}</span>
          </DashboardButton>
        </ButtonContainer>
        {showTabs && (
          <TimetableTabs
            timetableType={pageContext.timetableType}
            classesTab={{ url: memberTimetablePageBaseUrl, label: labels.timetableTabs.classes }}
            gymAccessSlotsTab={{
              url: memberGymAccessSlotPageBaseUrl,
              label: labels.timetableTabs.gymAccessSlots,
            }}
            navigationState={navigationState}
          />
        )}
        <Timetable
          type={pageContext.timetableType}
          gym={currentGym}
          allGyms={allGyms}
          onGymSelected={setSelectedGymId}
        />
      </>
    </Layout>
  );
};

export default MemberTimetable;

export const query = graphql`
  query {
    site {
      siteMetadata {
        theme
        locales
        locale
        memberTimetablePageBaseUrl
        memberGymAccessSlotPageBaseUrl
      }
    }
    gyms: allGymMetadata {
      nodes {
        name
        address {
          address1
          address2
          address3
          town
          postcode
        }
        id: gymId
        urlName
        genderRestriction
      }
    }
    labels {
      membersAreaHeading
      timetableTabs {
        classes
        gymAccessSlots
      }
      membersDashboardButtonText
      meta {
        pageTitle
      }
    }
    urls {
      authLogin
      authLogout
      accountMemberDashboard
    }
    flags {
      gymAccessSlotBookingEnabled
    }
  }
`;
