type WithSessionId<T> = T & { sessionId?: string };

export const save = <T>(key: string, item: T, sessionId: string): void =>
  sessionStorage.setItem(
    key,
    JSON.stringify({
      ...item,
      sessionId,
    })
  );

export const get = <T>(key: string, sessionId: string): T | null => {
  const rawItem = sessionStorage.getItem(key);

  if (rawItem) {
    const item = JSON.parse(rawItem) as WithSessionId<T>;

    if (item.sessionId === sessionId) {
      return item;
    }
  }

  return null;
};
