import React from "react";
import styled, { css } from "styled-components";
import { Button, Spinner } from "@puregym/ui";
import { useMemberType } from "src/hooks/useMemberType";
import { useLocale } from "src/providers/LocalizationProvider";
import { BookingAction, BookingButtonType } from "./shared";
import { ClassTypes, GymAccessSlotType, ScheduledClass } from "src/bookingsApi/models";

type StyledButtonProps = React.ComponentProps<typeof Button> & { isLoading: boolean };

const StyledButton = styled(Button)<StyledButtonProps>`
  padding: ${({ theme }) => theme.spacing.baseSpacing};
  margin-bottom: ${props => props.theme.spacing.doubleSpacing};
  ${({ isLoading, theme }) =>
    isLoading &&
    css`
      cursor: wait;

      &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
          color: ${theme.colors.contrastText};
        }
      }
    `};

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 1px;
  right: -33px;
`;

const ButtonContent = styled.div`
  position: relative;
`;

type BookingReasonKeys = keyof typeof BookingButtonType;

type BookingReasonType = {
  [key in Uncapitalize<BookingReasonKeys>]: string;
};

type BookingActionInProgress = {
  inProgress: {
    [key in BookingAction]: string;
  };
};

export type Translations = {
  bookingButton: {
    join: string;
  } & BookingReasonType &
    BookingActionInProgress;
  shared: {
    core: {
      loading: string;
    };
  };
};

const getButtonText = (
  labels: Translations["bookingButton"],
  isAnonymous: boolean,
  action: BookingAction,
  reason: BookingButtonType,
  isLoading: boolean
) => {
  if (isAnonymous) return labels.join;

  if (isLoading) {
    return labels.inProgress[action];
  }

  return labels[reason];
};

export interface BookingButtonProps {
  onClick: () => void;
  isLoading: boolean;
  action: BookingAction;
  scheduledClass: ScheduledClass;
}

type Processor = (buttonType: BookingButtonType, scheduledClass: ScheduledClass) => BookingButtonType;

const updateButtonTypeForInduction: Processor = (
  buttonType: BookingButtonType,
  scheduledClass: ScheduledClass
): BookingButtonType => {
  const isInduction = scheduledClass.classType === ClassTypes.induction;

  if (isInduction) {
    if (buttonType === BookingButtonType.BookClass) {
      return BookingButtonType.BookInduction;
    }
    if (buttonType === BookingButtonType.JoinClassWaitingList) {
      return BookingButtonType.JoinInductionWaitingList;
    }
  }

  return buttonType;
};

const updateButtonTypeForGymAccessSlot: Processor = (
  buttonType: BookingButtonType,
  scheduledClass: ScheduledClass
): BookingButtonType => {
  const isGymAccessSlot = scheduledClass.classType === GymAccessSlotType;

  if (isGymAccessSlot) {
    if (buttonType === BookingButtonType.BookClass) {
      return BookingButtonType.BookGymAccessSlot;
    }
    if (buttonType === BookingButtonType.CancelClass) {
      return BookingButtonType.CancelGymAccessSlot;
    }
    if (buttonType === BookingButtonType.JoinClassWaitingList) {
      return BookingButtonType.JoinGymAccessSlotWaitingList;
    }
  }

  return buttonType;
};

const getBookingButtonType = (action: BookingAction, scheduledClass: ScheduledClass): BookingButtonType => {
  let buttonType = BookingButtonType.NotSet;

  if (action === BookingAction.Cancel) {
    buttonType = BookingButtonType.CancelClass;
  } else if (action === BookingAction.LeaveWaitingList) {
    buttonType = BookingButtonType.LeaveWaitingList;
  } else if (action === BookingAction.Book) {
    buttonType = BookingButtonType.BookClass;
  } else if (action === BookingAction.JoinWaitingList) {
    buttonType = BookingButtonType.JoinClassWaitingList;
  }

  const processors = [updateButtonTypeForInduction, updateButtonTypeForGymAccessSlot];

  return processors.reduce<BookingButtonType>(
    (currentValue, processor) => processor(currentValue, scheduledClass),
    buttonType
  );
};

export const BookingButton: React.FC<BookingButtonProps> = ({
  onClick,
  isLoading,
  action,
  scheduledClass,
}: BookingButtonProps) => {
  const { getLabels } = useLocale();
  const labels = getLabels<Translations>();

  const { isAnonymous } = useMemberType();

  const bookingButtonType: BookingButtonType = getBookingButtonType(action, scheduledClass);
  const isDisabled = bookingButtonType === BookingButtonType.NotSet;

  return (
    <StyledButton fullWidth onClick={onClick} isLoading={isLoading} disabled={isDisabled}>
      <ButtonContent>
        {getButtonText(labels.bookingButton, isAnonymous, action, bookingButtonType, isLoading)}
        {isLoading && <StyledSpinner label={labels.shared.core.loading} size="small" />}
      </ButtonContent>
    </StyledButton>
  );
};
