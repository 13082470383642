import React from "react";
import { useAuthentication } from "src/hooks/useAuthentication";
import { navigate } from "../utils/navigation";
import { graphql, useStaticQuery } from "gatsby";
import { withReturnUrl } from "src/utils/navigation";

type QueryData = {
  urls: {
    authLogin: string;
  };
};

type Props = {
  children: React.ReactNode;
};

export const AuthenticatedPage = ({ children }: Props) => {
  const { isAuthenticated } = useAuthentication();

  const { urls } = useStaticQuery<QueryData>(graphql`
    query {
      urls {
        authLogin
      }
    }
  `);

  if (!isAuthenticated) {
    // TODO: If the auth cookie cannot be read for some reason, this will result in a redirect loop.
    // Keep track of the number of auth attempts (using local session storage?) and break out if necessary.
    const loginPageUrl = withReturnUrl(urls.authLogin);

    navigate(loginPageUrl);

    return null;
  }

  return children as React.ReactElement;
};
