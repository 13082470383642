import React, { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { Spinner, IconCheck } from "@puregym/ui";
import { ColumnStyles, listViewStyles } from "../../styles/listViewStyles";
import { MemberScheduledClassReason } from "src/models/booking";
import { useLocale } from "src/providers/LocalizationProvider";
import { darken } from "polished";
import { BookingError, WellKnownBookingRuleErrorMessageIds } from "src/bookingsApi/models";

export type CalendarCardStatusProps = {
  isWeekView: boolean;
  isLoading: boolean;
  remainingCapacity: number;
  remainingWaitingListCapacity: number;
  reason: MemberScheduledClassReason;
  columns: ColumnStyles;
  label?: string;
  bookingErrors: BookingError[];
};

const Status = styled.div<Pick<CalendarCardStatusProps, "isWeekView" | "columns" | "label">>`
  ${({ columns }) => columns.status};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-inline-start: 1px dotted;
  font-size: ${({ theme }) => theme.fontSizes.small};

  ${({ theme }) => theme.mediaQueries.lg} {
    min-height: 1.5rem;
  }

  ${({ isWeekView, label }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        flex-direction: row;
        padding: ${label ? "5px 0 10px" : "10px 0"};
        border-inline-start: 0;
        border-top: 1px solid ${props => props.theme.colors.accents.muted};
        width: 100%;
        font-size: ${({ theme }) => theme.fontSizes.standard};
        font-weight: 400;
      }
    `}

  ${({ isWeekView, label }) =>
    !isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        padding: ${label ? "10px" : "15px 10px"};
        ${listViewStyles.statusBorder}
      }
    `}
`;

const IconContainer = styled.span<{ isWeekView: boolean }>`
  margin-inline-end: 5px;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;

  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: ${({ theme }) => theme.fontSizes.standard};
        line-height: ${({ theme }) => theme.fontSizes.standard};
      }
    `}
`;

const SpinnerContainer = styled.div`
  margin-inline-start: ${({ theme }) => theme.spacing.baseSpacing};
`;

const BookingTextContainer = styled.span<{ isWeekView: boolean }>`
  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: ${({ theme }) => theme.fontSizes.standard};
      }
    `}
`;

const ClassCapacity = styled.span`
  font-size: 2.6rem;
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${({ theme }) => theme.fontSizes.standard};
    padding-inline-end: 5px;
  }
`;

const RemainingCapacityMessage = styled.div<{ isWeekView: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        flex-direction: row;
      }
    `}
`;

const LastSpaceMessage = styled(RemainingCapacityMessage)<{ isWeekView: boolean }>`
  color: ${props => darken(0.15, props.theme.colors.brandAccents.red)};
  align-items: center;

  ${ClassCapacity} {
    font-weight: bold;
  }
`;

const Booked = styled.div<{ isWeekView: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  ${({ isWeekView }) =>
    isWeekView &&
    css`
      ${({ theme }) => theme.mediaQueries.lg} {
        flex-direction: row;
      }
    `}
`;

export type Translations = {
  calendarCardStatus: {
    unavailable: string;
    classFull: string;
    booked: string;
    spaces: string;
    spaceRemaining: string;
    waitingList: string;
    loading: string;
    ageRestriction: string;
  };
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.div`
  background-color: ${props => props.theme.colors.opacities.inverted};
  font-size: ${props => props.theme.fontSizes.xsmall};
  font-weight: ${props => props.theme.fontWeights.heading};
  padding: ${props => props.theme.spacing.halfSpacing} 0;
  border-radius: ${props => props.theme.radii.default};
  background-color: ${props => darken(0.02, props.theme.colors.surface)};
  color: ${props => props.theme.colors.textMuted};
  margin-bottom: ${props => props.theme.spacing.halfSpacing};
`;

const getRemainingCapacity = (
  remainingCapacity: number,
  remainingWaitingListCapacity: number,
  labels: Translations["calendarCardStatus"],
  isWeekView: boolean
) => {
  if (remainingCapacity === 0) {
    if (remainingWaitingListCapacity > 0) {
      return labels.waitingList;
    } else {
      return labels.classFull;
    }
  } else if (remainingCapacity === 1) {
    return (
      <LastSpaceMessage isWeekView={isWeekView}>
        <ClassCapacity>{remainingCapacity}</ClassCapacity>
        <span>{labels.spaceRemaining}</span>
      </LastSpaceMessage>
    );
  } else {
    // remainingCapacity > 1
    return (
      <RemainingCapacityMessage isWeekView={isWeekView}>
        <ClassCapacity>{remainingCapacity}</ClassCapacity>
        <span>{labels.spaces}</span>
      </RemainingCapacityMessage>
    );
  }
};

const getStatus = (props: CalendarCardStatusProps) => {
  const { getLabels } = useLocale();
  const { calendarCardStatus: labels } = getLabels<Translations>();

  const { remainingCapacity, remainingWaitingListCapacity, reason, isLoading, label, isWeekView, bookingErrors } =
    props;
  const theme = useContext(ThemeContext);

  const booked = reason === MemberScheduledClassReason.AlreadyBooked;
  const onWaitingList = reason === MemberScheduledClassReason.AlreadyOnWaitingList;

  const getBookedComponent = (statusLabel: string) => {
    return (
      <Booked isWeekView={isWeekView}>
        <IconContainer isWeekView={isWeekView}>
          <IconCheck aria-hidden />
        </IconContainer>
        <BookingTextContainer isWeekView={isWeekView}>{statusLabel}</BookingTextContainer>
      </Booked>
    );
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner
          label={labels.loading}
          color={theme.colors.accents.light}
          emptyColor={theme.colors.primary.dark}
          thickness="6px"
          size="large"
          speed=".8s"
          data-testid="loadingSpinner"
        />
      </SpinnerContainer>
    );

  if (booked) return getBookedComponent(labels.booked);
  if (onWaitingList) return getBookedComponent(labels.waitingList);

  const showLabel = !!label;

  const membershipIsValidForClass = !bookingErrors.some(
    error =>
      error.errorCode === WellKnownBookingRuleErrorMessageIds.MembershipNotValidDuringClassTime ||
      error.errorCode === WellKnownBookingRuleErrorMessageIds.MembershipDoesNotAllowParticipationInThisClass ||
      error.errorCode === WellKnownBookingRuleErrorMessageIds.MemberNotOldEnoughToBookClass
  );

  const memberUnder18 = bookingErrors.some(
    error => error.errorCode === WellKnownBookingRuleErrorMessageIds.MemberNotOldEnoughToBookClass
  );

  const content =
    [MemberScheduledClassReason.NotSet, MemberScheduledClassReason.FullyBooked].includes(reason) &&
    membershipIsValidForClass
      ? getRemainingCapacity(remainingCapacity, remainingWaitingListCapacity, labels, isWeekView)
      : memberUnder18
      ? labels.ageRestriction
      : labels.unavailable;

  if (showLabel) {
    return (
      <Column>
        <Label>{label}</Label>
        {content}
      </Column>
    );
  }

  return content;
};

export const CalendarCardStatus: React.FC<CalendarCardStatusProps> = (props: CalendarCardStatusProps) => {
  const statusProps = {
    isWeekView: props.isWeekView,
    label: props.label,
    columns: props.columns,
    bookingErrors: props.bookingErrors,
  };

  return (
    <Status data-testid="calendarCardStatus" {...statusProps}>
      {getStatus(props)}
    </Status>
  );
};
