import React, { useRef } from "react";
import styled, { css, DefaultTheme } from "styled-components";
import themeFor, { ThemeSet } from "styled-theming";
import { lighten, darken } from "polished";
import { Button, IconGrid, IconList } from "@puregym/ui";
import { RovingTabIndexProvider, useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import { useLocale } from "src/providers/LocalizationProvider";

import { CalendarMode } from "../Calendar/Calendar";

const Container = styled.div``;

const getSecondaryColor = ({ theme }: { theme: DefaultTheme }): ThemeSet =>
  themeFor("mode", {
    light: lighten(0.2, theme.colors.textMuted),
    dark: darken(0.2, theme.colors.textMuted),
  });

const StyledButton = styled(Button)<{ isActive: boolean }>`
  margin-inline-start: ${({ theme }) => theme.spacing.quarterSpacing};
  padding: ${({ theme }) => `${theme.spacing.quarterSpacing} ${theme.spacing.baseSpacing}`};

  border-radius: ${({ theme }) => theme.radii.double};
  border: 1px solid;
  border-color: ${getSecondaryColor};
  color: ${getSecondaryColor};

  line-height: ${({ theme }) => theme.lineHeights.body};
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:not(:disabled):hover,
  &:not(:disabled):focus:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.contrastText};
  }

  &:not(:disabled):focus {
    border-color: none;
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  &:not(:disabled):focus {
    color: ${({ isActive, theme }) => (isActive ? theme.colors.primary.main : getSecondaryColor)};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
    border-color: ${({ isActive, theme: { colors } }) => (isActive ? colors.primary.main : colors.textMuted)};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.primary.main};
    `};

  > svg {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export type Translations = {
  calendarModeSelector: {
    weekView: string;
    listView: string;
    accessible: {
      title: string;
    };
  };
};

interface CalendarModeButtonProps {
  mode: CalendarMode;
  currentMode: CalendarMode;
  children: JSX.Element | JSX.Element[];
  onClick: (value: CalendarMode) => void;
}

const CalendarModeButton: React.FC<CalendarModeButtonProps> = ({
  mode,
  onClick,
  currentMode,
  children,
}: CalendarModeButtonProps) => {
  const isActive = mode === currentMode;

  const ref = useRef<HTMLButtonElement>(null);

  const [tabIndex, focused, handleKeyDownForFocus, handleClickForFocus] = useRovingTabIndex(ref, false);

  useFocusEffect(focused, ref);

  const handleButtonClick = (): void => {
    handleClickForFocus();
    onClick(mode);
  };

  const handleKeyDown = (event: React.KeyboardEvent<Element>): void => {
    if (event.key === "Enter" || event.key === "Space") {
      onClick(mode);
    } else {
      handleKeyDownForFocus(event);
    }
  };

  return (
    <StyledButton
      variant="plain"
      isActive={isActive}
      role="radio"
      aria-checked={isActive}
      tabIndex={tabIndex}
      ref={ref}
      onKeyDown={handleKeyDown}
      onClick={handleButtonClick}
    >
      {children}
    </StyledButton>
  );
};

export interface CalendarModeSelectorProps {
  activeMode: CalendarMode;
  onCalendarModeSelected: (calendarMode: CalendarMode) => void;
}

const CalendarModeSelector: React.FC<CalendarModeSelectorProps> = ({
  activeMode: currentMode,
  onCalendarModeSelected,
}: CalendarModeSelectorProps) => {
  const { getLabels } = useLocale();
  const { calendarModeSelector: labels } = getLabels<Translations>();

  return (
    <Container role="radiogroup" aria-label={labels.accessible.title}>
      <RovingTabIndexProvider options={{ direction: "both" }}>
        <CalendarModeButton mode="WeekView" currentMode={currentMode} onClick={onCalendarModeSelected}>
          <IconGrid aria-hidden />
          <span>{labels.weekView}</span>
        </CalendarModeButton>
        <CalendarModeButton mode="ListView" currentMode={currentMode} onClick={onCalendarModeSelected}>
          <IconList aria-hidden />
          <span>{labels.listView}</span>
        </CalendarModeButton>
      </RovingTabIndexProvider>
    </Container>
  );
};

const CalendarModeSelectorMemoized = React.memo<CalendarModeSelectorProps>(CalendarModeSelector);

export { CalendarModeSelectorMemoized as CalendarModeSelector };
