import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import themeFor, { ThemeSet } from "styled-theming";
import { lighten, darken } from "polished";
import { Button, IconArrowLeft, IconArrowRight } from "@puregym/ui";
import { Week } from "src/models/time";
import { threeQuarterSpacing } from "../../styles/sharedStyles";
import { useLocale } from "src/providers/LocalizationProvider";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const getSecondaryColor = ({ theme }: { theme: DefaultTheme }): ThemeSet =>
  themeFor("mode", {
    light: lighten(0.2, theme.colors.textMuted),
    dark: darken(0.2, theme.colors.textMuted),
  });

type IconPosition = "start" | "end";

const StyledButton = styled(Button)<{ isEnabled: boolean; iconPosition: IconPosition }>`
  padding-top: ${({ theme }) => theme.spacing.halfSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.halfSpacing};
  padding-inline-start: ${({ theme, iconPosition }) =>
    iconPosition === "start" ? theme.spacing.quarterSpacing : threeQuarterSpacing};
  padding-inline-end: ${({ theme, iconPosition }) =>
    iconPosition === "start" ? threeQuarterSpacing : theme.spacing.quarterSpacing};

  border-radius: ${({ theme }) => theme.radii.double};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  line-height: 1.3;

  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.textMuted};

  &:not(:disabled):hover,
  &:not(:disabled):focus:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.contrastText};
  }

  &:not(:disabled):focus {
    color: ${({ isEnabled, theme }) => (isEnabled ? theme.colors.primary.main : getSecondaryColor)};
    border-color: none;
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  ${({ isEnabled }) =>
    isEnabled &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.primary.contrastText};
    `};

  > svg:first-child + * {
    margin-inline-start: ${({ theme, iconPosition }) => (iconPosition === "start" ? theme.spacing.quarterSpacing : 0)};
    margin-inline-end: ${({ theme, iconPosition }) => (iconPosition === "start" ? 0 : theme.spacing.quarterSpacing)};
  }

  > svg {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};

    html[dir="rtl"] & {
      transform: scaleX(-1);
    }
  }
`;

export type Translations = {
  weekSelector: {
    previousWeek: string;
    nextWeek: string;
  };
};

interface WeekSelectorButtonProps {
  week: Week;
  currentWeek: Week;
  children: JSX.Element | JSX.Element[];
  onClick: (value: Week) => void;
  iconPosition: IconPosition;
}

const WeekSelectorButton: React.FC<WeekSelectorButtonProps> = ({
  week,
  onClick,
  currentWeek,
  children,
  iconPosition,
}: WeekSelectorButtonProps) => {
  const isEnabled = week !== currentWeek;

  const handleButtonClick = (): void => {
    onClick(week);
  };

  const handleKeyDown = (event: React.KeyboardEvent<Element>): void => {
    if (event.key === "Enter" || event.key === "Space") {
      onClick(week);
    }
  };

  return (
    <StyledButton
      variant="plain"
      isEnabled={isEnabled}
      disabled={!isEnabled}
      aria-disabled={!isEnabled}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleButtonClick}
      iconPosition={iconPosition}
    >
      {children}
    </StyledButton>
  );
};

export interface WeekSelectorProps {
  currentWeek: Week;
  onWeekSelected: (week: Week) => void;
}

const WeekSelector: React.FC<WeekSelectorProps> = ({ currentWeek, onWeekSelected }: WeekSelectorProps) => {
  const { getLabels } = useLocale();
  const { weekSelector: labels } = getLabels<Translations>();

  return (
    <Container>
      <WeekSelectorButton week="current" currentWeek={currentWeek} onClick={onWeekSelected} iconPosition="start">
        <IconArrowLeft aria-hidden />
        <span>{labels.previousWeek}</span>
      </WeekSelectorButton>
      <WeekSelectorButton week="next" currentWeek={currentWeek} onClick={onWeekSelected} iconPosition="end">
        <span>{labels.nextWeek}</span>
        <IconArrowRight aria-hidden />
      </WeekSelectorButton>
    </Container>
  );
};

const WeekSelectorMemoized = React.memo<WeekSelectorProps>(WeekSelector);

export { WeekSelectorMemoized as WeekSelector };
