import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import getTimeZoneName from "../../src/utils/dayjs/plugin/getTimeZoneName";
import toIsoDateString from "../../src/utils/dayjs/plugin/toIsoDateString";

export const initialiseDayjsPlugins = (): void => {
  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(toIsoDateString);
  dayjs.extend(localizedFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(getTimeZoneName);
};
