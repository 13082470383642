import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { darken } from "polished";
import themeFor, { ThemeSet } from "styled-theming";
import { ColumnStyles, listViewStyles } from "../../styles/listViewStyles";
import { TimetableType } from "src/models/timetableType";
import { useLocale } from "src/providers/LocalizationProvider";

const getBackgroundColor = ({ theme }: { theme: DefaultTheme }): string => darken(0.1, theme.colors.primary.dark);

const getBorderColor = ({ theme }: { theme: DefaultTheme }): ThemeSet =>
  themeFor("mode", {
    light: theme.colors.opacities.invertedSubtle,
    dark: getBackgroundColor({ theme }),
  });

type ColumnProps = {
  columns: ColumnStyles;
};

const Column = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const ClassName = styled(Column)<ColumnProps>`
  ${({ columns }) => columns.className}
`;

const Time = styled(Column)<ColumnProps>`
  ${({ columns }) => columns.time}
`;

const Studio = styled(Column)<ColumnProps>`
  ${({ columns }) => columns.studio}
`;

const Instructor = styled(Column)<ColumnProps>`
  ${({ columns }) => columns.instructor}
`;

const ClassInfo = styled(Column)<ColumnProps>`
  ${({ columns }) => columns.status}
  ${listViewStyles.statusBorder}
`;

const Container = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
    padding-inline-start: ${listViewStyles.rowPaddingHorizontal};

    border-left: 1px solid ${getBorderColor};
    border-right: 1px solid ${getBorderColor};
    background-color: ${getBackgroundColor};
    color: ${({ theme }) => theme.colors.primary.contrastText};

    font-weight: ${({ theme }) => theme.fontWeights.heading};
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export type Translations = {
  calendarDayListHeader: {
    class: string;
    timeAndDuration: string;
    studio: string;
    instructor: string;
    classInfo: string;
  };
};

export interface CalendarDayListHeaderProps {
  variant: TimetableType;
  showClassInfo?: boolean;
}

export const CalendarDayListHeader: React.FC<CalendarDayListHeaderProps> = ({
  variant,
  showClassInfo = false,
}: CalendarDayListHeaderProps) => {
  const { getLabels } = useLocale();
  const { calendarDayListHeader: labels } = getLabels<Translations>();

  const showStudio = variant !== "gymAccessSlot";
  const showInstructor = variant !== "gymAccessSlot";

  const columnStyles = listViewStyles.columns[variant];
  const cellProps: ColumnProps = { columns: columnStyles };

  return (
    <Container aria-hidden={true} role="heading">
      <ClassName {...cellProps}>{labels.class}</ClassName>
      <Time {...cellProps}>{labels.timeAndDuration}</Time>
      {showStudio && <Studio {...cellProps}>{labels.studio}</Studio>}
      {showInstructor && <Instructor {...cellProps}>{labels.instructor}</Instructor>}
      {showClassInfo && <ClassInfo {...cellProps}>{labels.classInfo}</ClassInfo>}
    </Container>
  );
};
