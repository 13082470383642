// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../declarations/png.d.ts" />
import React from "react";
import { HeadProps } from "gatsby";
import puregymIcon from "./puregym-icon.png";

// Source: https://github.com/shadiabuhilal/rtl-detect/blob/master/lib/rtl-detect.js
const rtlLanguages = [
  "ae" /* Avestan */,
  "ar" /* 'العربية', Arabic */,
  "arc" /* Aramaic */,
  "bcc" /* 'بلوچی مکرانی', Southern Balochi */,
  "bqi" /* 'بختياري', Bakthiari */,
  "ckb" /* 'Soranî / کوردی', Sorani */,
  "dv" /* Dhivehi */,
  "fa" /* 'فارسی', Persian */,
  "glk" /* 'گیلکی', Gilaki */,
  "he" /* 'עברית', Hebrew */,
  "ku" /* 'Kurdî / كوردی', Kurdish */,
  "mzn" /* 'مازِرونی', Mazanderani */,
  "nqo" /* N'Ko */,
  "pnb" /* 'پنجابی', Western Punjabi */,
  "ps" /* 'پښتو', Pashto, */,
  "sd" /* 'سنڌي', Sindhi */,
  "ug" /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
  "ur" /* 'اردو', Urdu */,
  "yi" /* 'ייִדיש', Yiddish */,
];

const isRtlLanguage = (locale: string) => rtlLanguages.some(twoLetterCode => locale.startsWith(twoLetterCode));

const getLanguageDirection = (locale: string) => (isRtlLanguage(locale) ? "rtl" : "ltr");

type DataProps = {
  site: {
    siteMetadata: {
      locale: string;
    };
  };
  labels: {
    meta: {
      pageTitle: string;
      description: string;
    };
  };
};

export const Head = (props: HeadProps<DataProps>) => {
  const { site, labels } = props.data;

  const locale = site.siteMetadata.locale;
  const languageDirection = getLanguageDirection(locale);

  return (
    <>
      <html lang={locale} dir={languageDirection} />
      <title>{labels.meta.pageTitle}</title>
      <meta name="description" content={labels.meta.description} />
      <meta property="og:image" content={`/${puregymIcon}`} />
    </>
  );
};
