import { EntityId } from "src/bookingsApi/models";
import { hasParameter, getParameter, insertUrlParameter, removeUrlParameter } from "./urlHelper";

const urlParameter = "selectedClassId";

export const hasSelectedClassIdInUrl = (): boolean => hasParameter(urlParameter);

export const getSelectedClassIdInUrl = (): EntityId | null => {
  const value = getParameter(urlParameter);
  return value ? new EntityId(value) : null;
};

export const setSelectedClassIdInUrl = (id: EntityId): void => insertUrlParameter(urlParameter, id.toString());

export const removeSelectedClassIdFromUrl = (): void => removeUrlParameter(urlParameter);
