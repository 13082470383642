import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Settings } from "../localization/localizedSettings";
import { AllLabels, LocalizationProvider } from "./LocalizationProvider";

type QueryData = {
  site: {
    siteMetadata: {
      locale: string;
    };
  };
  labels: AllLabels;
  settings: {
    bookings: Settings;
  };
};

type Props = {
  children: React.ReactNode;
};

export const StaticLocalizationProvider = ({ children }: Props) => {
  const { site, labels, settings } = useStaticQuery<QueryData>(graphql`
    query {
      site {
        siteMetadata {
          locale
        }
      }
      labels {
        ...translationFields
      }
      settings: labels {
        ...settingsFields
      }
    }
  `);

  return (
    <LocalizationProvider locale={site.siteMetadata.locale} labels={labels} settings={settings.bookings}>
      {children}
    </LocalizationProvider>
  );
};
