import { Dayjs } from "dayjs";
import { Time } from "../models/time";

const dateOnly = (dateTime: Dayjs) => dateTime.startOf("day");

export const getNumberOfDaysBetween = (minDateTime: Dayjs, maxDateTime: Dayjs): number => {
  const minDate = dateOnly(minDateTime);
  const maxDate = dateOnly(maxDateTime);
  const diff = maxDate.diff(minDate, "day", true);

  return Math.round(diff) + 1;
};

export const getMax = (day1: Dayjs, day2: Dayjs): Dayjs => (day1.isAfter(day2) ? day1 : day2);

export const getMin = (day1: Dayjs, day2: Dayjs): Dayjs => (day1.isBefore(day2) ? day1 : day2);

export const getTime = (dateTime: Dayjs): Time => ({ hours: dateTime.hour(), minutes: dateTime.minute() });

export default { min: getMin, max: getMax };
