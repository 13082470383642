import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import themeFor, { ThemeSet } from "styled-theming";
import { replaceTokens } from "../../../utils/stringUtils";
import { threeQuarterSpacing } from "../../../styles/sharedStyles";
import { useLocale } from "src/providers/LocalizationProvider";
import { ScheduledClass } from "src/bookingsApi/models";
import { Gym } from "src/models/gym";
import { AddToCalendar } from "src/components/AddToCalendar/AddToCalendar";
import { TimetableType } from "src/models/timetableType";
import { Dayjs } from "dayjs";

const List = styled.dl`
  margin-block-start: 0;
  margin-bottom: ${props => props.theme.spacing.doubleSpacing};

  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const cellStyles = css`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.accents.muted};
  padding: ${props => props.theme.spacing.quarterSpacing} ${threeQuarterSpacing};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${threeQuarterSpacing};
    border-bottom: 3px solid ${props => props.theme.colors.background};
    background-color: ${({ theme }) => theme.colors.accents.muted};

    :last-of-type {
      border-bottom: none;
    }
  }
`;

const getNameColor = ({ theme }: { theme: DefaultTheme }): ThemeSet =>
  themeFor("mode", {
    light: theme.colors.opacities.invertedStrong,
    dark: theme.colors.opacities.strong,
  });

const Name = styled.dt`
  ${cellStyles}
  flex: 30%;
  color: ${getNameColor};
`;

const Value = styled.dd`
  ${cellStyles}
  flex: 70%;
  margin-left: 0;
  margin-right: 0;
  border-inline-start: 2px dotted ${props => props.theme.colors.background};
  border-bottom: 3px solid ${props => props.theme.colors.background};
  background-color: ${({ theme }) => theme.colors.opacities.invertedSubtle};
`;

const StartTimeValue = styled(Value)`
  display: flex;
  justify-content: space-between;
`;

export type Settings = {
  classDetails: {
    dateTimeFormat: string;
  };
};

export type Translations = {
  classDetails: {
    startDateTime: string;
    duration: string;
    durationValue: string;
    accessible: {
      title: string;
      durationValue: string;
    };
    studio: string;
    instructor: string;
    gym: string;
    energyConsumption: string;
    energyConsumptionValue: string;
    spaces: string;
    waitingListPosition: string;
  };
};

export type ClassDetailsProps = Pick<
  ScheduledClass,
  "startDateTime" | "duration" | "studio" | "energyConsumptionKcal" | "instructorName" | "name" | "waitingListPosition"
> & {
  showRemainingCapacity: boolean;
  timetableType: TimetableType;
  gym: Gym;
  remainingCapacity: number | string;
  showAddToCalendar: boolean;
  showWaitingListPosition: boolean;
};

const defaultDateTimeFormat = "HH:mm dddd Do MMMM YYYY";

const formatClassStartTime = (date: Dayjs) => {
  const { formatDateWithPattern: formatDate } = useLocale();
  const getDateFormat = (settings: Settings) => settings.classDetails.dateTimeFormat;
  return formatDate(date, getDateFormat, defaultDateTimeFormat);
};

export const ClassDetails: React.FC<ClassDetailsProps> = ({
  showRemainingCapacity,
  name: className,
  timetableType,
  startDateTime,
  duration,
  energyConsumptionKcal,
  studio,
  instructorName,
  remainingCapacity,
  gym,
  showAddToCalendar,
  showWaitingListPosition,
  waitingListPosition,
}: ClassDetailsProps) => {
  const { getLabels } = useLocale();
  const { classDetails: labels } = getLabels<Translations>();

  const formattedDuration = replaceTokens(labels.durationValue, { duration });
  const ariaLabelDuration = replaceTokens(labels.accessible.durationValue, { duration });

  const formattedEnergyConsumption = energyConsumptionKcal
    ? replaceTokens(labels.energyConsumptionValue, { energyConsumptionKcal })
    : "";

  const isGymAccessSlot = timetableType === "gymAccessSlot";
  const showInstructor = !isGymAccessSlot;
  const showStudio = !isGymAccessSlot;
  const formattedDateTime = formatClassStartTime(startDateTime);

  const renderAddToCalendar = () =>
    showAddToCalendar && (
      <AddToCalendar
        timetableType={timetableType}
        className={className}
        startDateTime={startDateTime}
        duration={duration}
        instructorName={instructorName}
        studio={studio}
        gym={gym}
      />
    );

  return (
    <List aria-label={labels.accessible.title}>
      <Name>{labels.startDateTime}</Name>
      <StartTimeValue>
        <time dateTime={startDateTime.format("YYYY-MM-DD HH:mm")}>{formattedDateTime}</time>
        {renderAddToCalendar()}
      </StartTimeValue>

      <Name>{labels.duration}</Name>
      <Value aria-label={ariaLabelDuration}>{formattedDuration}</Value>

      {formattedEnergyConsumption && (
        <>
          <Name>{labels.energyConsumption}</Name>
          <Value>{formattedEnergyConsumption}</Value>
        </>
      )}

      <Name>{labels.gym}</Name>
      <Value>{gym.name}</Value>

      {showStudio && (
        <>
          <Name>{labels.studio}</Name>
          <Value>{studio}</Value>
        </>
      )}

      {showInstructor && (
        <>
          <Name>{labels.instructor}</Name>
          <Value>{instructorName}</Value>
        </>
      )}

      {showRemainingCapacity && (
        <>
          <Name>{labels.spaces}</Name>
          <Value>{remainingCapacity}</Value>
        </>
      )}

      {showWaitingListPosition && (
        <>
          <Name>{labels.waitingListPosition}</Name>
          <Value>{waitingListPosition}</Value>
        </>
      )}
    </List>
  );
};
