import React, { useContext, useReducer, useMemo, ReactNode } from "react";
import timetableReducer, {
  Action,
  initialState,
  Labels,
  REDIRECT_TO_URL,
  OPEN_MODAL,
  CLOSE_MODAL,
  DESELECT_CLASS,
  TimetableState,
} from "./store";
import { useLocale } from "src/providers/LocalizationProvider";
import { removeSelectedClassIdFromUrl, setSelectedClassIdInUrl } from "./selectedClassUrlHelper";

type Required<T> = T extends Record<string, unknown> ? { [P in keyof T]-?: NonNullable<T[P]> } : T;

type RequiredFields = Required<Pick<TimetableState, "timetableType">>;

export type TimetableContextType = TimetableState & { dispatch: React.Dispatch<Action> } & RequiredFields;

export type Translations = {
  timetable: {
    gymAccessSlot: {
      pageHeading: string;
      noBookableActivitiesOnThisDay: string;
    };
    scheduledClass: {
      pageHeading: string;
      noBookableActivitiesOnThisDay: string;
    };
    genericApiError: string;
  };
  shared: {
    core: {
      loading: string;
    };
  };
};

const applyMiddleware = (dispatch: React.Dispatch<Action>) => (action: Action) => {
  if (action.type === REDIRECT_TO_URL) {
    window.location.href = action.url;
  } else if (action.type === OPEN_MODAL) {
    setSelectedClassIdInUrl(action.scheduledClass.id);
  } else if (action.type === CLOSE_MODAL || action.type === DESELECT_CLASS) {
    removeSelectedClassIdFromUrl();
  }

  return dispatch(action);
};

const TimetableContext = React.createContext<TimetableContextType | undefined>(undefined);

export type TimetableInitialValue = Partial<TimetableState> & RequiredFields;

type TimetableProviderProps = {
  initialValue: TimetableInitialValue;
  children: ReactNode;
};

const TimetableProvider: React.FC<TimetableProviderProps> = ({
  initialValue,
  children,
}: TimetableProviderProps): JSX.Element => {
  return <TimetableContext.Provider value={getContext(initialValue)}>{children}</TimetableContext.Provider>;
};

const getContext = ({ timetableType, ...initialValues }: TimetableInitialValue): TimetableContextType => {
  const { getLabels } = useLocale();
  const labels = getLabels<Translations>();

  const allLabels: Labels = {
    ...labels,
    timetable: {
      genericApiError: labels.timetable.genericApiError,
      ...labels.timetable[timetableType],
    },
  };

  const [state, dispatch] = useReducer(timetableReducer, {
    ...initialState,
    ...initialValues,
    labels: allLabels,
    timetableType,
  });

  const enhancedDispatch = applyMiddleware(dispatch);

  const providerValue = useMemo(
    () => ({
      ...state,
      dispatch: enhancedDispatch,
    }),
    [state, dispatch]
  );

  return providerValue;
};

export default TimetableContext;

const useTimetable = (): TimetableContextType => {
  const context = useContext(TimetableContext);
  if (context === undefined) {
    throw new Error("useTimetable must be used within a TimetableProvider");
  }
  return context;
};

export { TimetableProvider, useTimetable };
