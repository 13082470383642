export enum MemberScheduledClassReason {
  NotSet = "NotSet",
  FullyBooked = "FullyBooked",
  LoggedOut = "LoggedOut",
  AlreadyBooked = "AlreadyBooked",
  PhoneMemberServices = "PhoneMemberServices",
  AlreadyOnWaitingList = "AlreadyOnWaitingList",
  InvalidMembershipType = "InvalidMembershipType",
  DuplicateCourseBooking = "DuplicateCourseBooking",
  AlreadyStarted = "AlreadyStarted",
  MembershipNotActive = "MembershipNotActive",
  DigitalClass = "DigitalClass",
  TooManyClassesBooked = "TooManyClassesBooked",
  StartTimeConflict = "StartTimeConflict",
  TooFarInAdvance = "TooFarInAdvance",
  NonBookable = "NonBookable",
  GymAccessSlotNotBooked = "GymAccessSlotNotBooked",
  MembershipDoesNotAllowParticipation = "MembershipDoesNotAllowParticipation",
  MemberNotOldEnoughToBookClass = "MemberNotOldEnoughToBookClass",
}
