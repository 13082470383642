import { useState, useEffect } from "react";

/**
 * This hook checks if the viewport matches the query passed.
 * Based on https://material-ui.com/components/use-media-query/.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
 */
export const useMediaQuery = (query: string, options = {}) => {
  const supportMatchMedia = typeof window !== "undefined" && typeof window.matchMedia !== "undefined";

  const queryValue = query.replace(/^@media( ?)/m, "");

  const { defaultMatches = false, matchMedia = supportMatchMedia ? window.matchMedia : null } = {
    ...options,
  };

  const [match, setMatch] = useState(() => defaultMatches);

  useEffect(() => {
    if (!supportMatchMedia || !matchMedia) {
      return undefined;
    }

    const queryList = matchMedia(queryValue);

    const updateMatch = () => {
      setMatch(queryList.matches);
    };

    updateMatch();

    queryList.addEventListener("change", updateMatch);

    return () => queryList.removeEventListener("change", updateMatch);
  }, [queryValue, matchMedia, supportMatchMedia]);

  return match;
};
