import { Link } from "gatsby";
import React from "react";

export const TabLink: React.FC<{ isSelected: boolean; to: string; props: unknown }> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSelected,
  to,
  ...props
}: {
  isSelected: boolean;
  to: string;
  props: unknown;
}) => <Link to={to} {...props} />;
