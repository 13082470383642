import React, { ReactNode, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { Page, PageMain, ThemeProvider, themes, Theme } from "@puregym/ui";
import Header from "@puregym/gatsby-plugin-header";
import Footer from "@puregym/gatsby-plugin-footer";
import { initCookieConsent } from "@puregym/cookieconsent";
import { Translations as SiteHeaderLabels } from "../declarations/SiteHeaderLabels";
import { Translations as SiteFooterLabels } from "../declarations/SiteFooterLabels";
import type { Locale } from "config/types";
import { withReturnUrl } from "src/utils/navigation";

import "../../node_modules/@puregym/ui/dist/static/css/fonts.css";

const PageContainer = styled(PageMain).attrs({ fullWidth: true })`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SiteHeader = styled(Header)`
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const Main = styled(PageMain)`
  flex: 1;
`;

type QueryData = {
  site: {
    siteMetadata: {
      brand: string;
      brandName: string;
      defaultLocale: Locale;
      siteUrl: string;
      enableCookieConsent: boolean;
      locale: Locale;
      rootDomain: string;
      twitterUsername: string;
    };
  };
  labels: SiteHeaderLabels & SiteFooterLabels;
};

interface LayoutProps {
  pageTitle: string;
  children: ReactNode;
  theme: Theme;
  supportedLocales?: Locale[];
  urlsByLocale?: Partial<Record<Locale, string>>;
  urls: {
    authLogin: string;
    authLogout: string;
  };
}

export const Layout = ({ theme, children, supportedLocales, urlsByLocale, urls }: LayoutProps): JSX.Element => {
  const currentTheme = themes[theme];

  const localizedBasePaths = supportedLocales?.reduce((obj: Record<string, string>, locale: Locale) => {
    obj[locale] = (urlsByLocale && urlsByLocale[locale]) || "";
    return obj;
  }, {});

  const authUrls = {
    authLogin: withReturnUrl(urls.authLogin),
    authLogout: urls.authLogout,
  };

  const {
    site: { siteMetadata },
    labels: {
      shared: { core: labels },
    },
  } = useStaticQuery<QueryData>(graphql`
    query {
      site {
        siteMetadata {
          brand
          brandName
          defaultLocale
          siteUrl
          enableCookieConsent
          locale
          rootDomain
          twitterUsername
        }
      }
      labels {
        shared {
          core {
            cookieConsentModify
            menuBackText
            closeMenuButton
            menuTitle
            menuButton
            logInButton
            logOutButton
            languageSelectorTitle
          }
        }
      }
    }
  `);

  const languageCode = siteMetadata.locale.substring(0, 2);
  const enableCookieConsent = siteMetadata.enableCookieConsent;

  useEffect(() => {
    enableCookieConsent && initCookieConsent(languageCode);
  }, [enableCookieConsent, languageCode]);

  const headerProps = {
    urls: authUrls,
    labels,
    ...siteMetadata,
  };

  const footerProps = {
    localizedBasePaths,
    title: labels.languageSelectorTitle,
    ...siteMetadata,
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <Page>
        <PageContainer>
          <SiteHeader {...headerProps} />
          <Main as="main">{children}</Main>
          <Footer {...footerProps} />
        </PageContainer>
      </Page>
    </ThemeProvider>
  );
};

export default Layout;
