import { PluginFunc } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    /**       
      @returns date in YYYY-MM-DD format 
     */
    toIsoDateString(): string;
  }
}

const toIsoDateString: PluginFunc = (_option, dayjsClass) => {
  const isoDateFormat = "YYYY-MM-DD";

  dayjsClass.prototype.toIsoDateString = function () {
    return this.format(isoDateFormat);
  };
};

export default toIsoDateString;
