import React from "react";
import { SelectField } from "@puregym/ui";
import { Time, TimeRange } from "../../models/time";
import { useLocale } from "src/providers/LocalizationProvider";

export type Translations = {
  timeRangeFilter: {
    title: string;
    allDay: string;
    morning: string;
    noon: string;
    afternoon: string;
    evening: string;
  };
};

export type TimeRangeSelectedCallback = (filter: TimeRange | null) => void;

export interface TimeRangeFilterProps {
  onTimeRangeSelected: TimeRangeSelectedCallback;
}

type TimeRanges = "allDay" | "morning" | "noon" | "afternoon" | "evening";

const hours = (hour: number): Time => ({
  hours: hour,
  minutes: 0,
});

const TimeRangeFilter: React.FC<TimeRangeFilterProps> = ({ onTimeRangeSelected }: TimeRangeFilterProps) => {
  const { getLabels } = useLocale();
  const { timeRangeFilter: labels } = getLabels<Translations>();

  const options: Array<{ name: string; value: TimeRanges; filter: TimeRange | null }> = [
    { name: labels.allDay, value: "allDay", filter: null },
    { name: labels.morning, value: "morning", filter: { from: hours(6), to: hours(9) } },
    { name: labels.noon, value: "noon", filter: { from: hours(9), to: hours(12) } },
    { name: labels.afternoon, value: "afternoon", filter: { from: hours(12), to: hours(17) } },
    { name: labels.evening, value: "evening", filter: { from: hours(17), to: hours(21) } },
  ];

  const optionLookup = Object.fromEntries(options.map(option => [option.value, option]));

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    onTimeRangeSelected(optionLookup[selectedValue].filter);
  };

  return (
    <SelectField label={labels.title} name="timeRangeFilter" onChange={onChange}>
      {options.map(({ name, value }) => (
        <option key={value} value={value}>
          {name}
        </option>
      ))}
    </SelectField>
  );
};

const TimeRangeFilterMemoized = React.memo<TimeRangeFilterProps>(TimeRangeFilter);

export { TimeRangeFilterMemoized as TimeRangeFilter };
