import React from "react";
import { ScheduledClass } from "src/bookingsApi/models";
import styled from "styled-components";
import { Dropdown, List } from "@puregym/ui";
import { buildCalendarUrls, CalendarEvent } from "./buildCalendarUrls";
import { replaceTokens } from "src/utils/stringUtils";
import { Gym } from "src/models/gym";
import { TimetableType } from "src/models/timetableType";
import { useLocale } from "src/providers/LocalizationProvider";
import { useConfig } from "src/providers/ConfigProvider";

const config = {
  addressFormat: "address1,address2,address3,town,postcode",
  titleFormat: "{brandName} {gymName} - {className}",
  locationFormat: "{brandName}, {gymAddress}",
};

const Link = styled.a`
  display: block;
  padding: 3px 15px;
  text-decoration: none;
  transition: none;
  font-size: ${props => props.theme.fontSizes.small};
  line-height: 1.3;

  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};

  &:hover {
    background-color: ${props => props.theme.colors.primary.main};
    color: ${props => props.theme.colors.contrastText};
  }
`;

export type Config = {
  brandDisplayName: string;
  memberServicesEmailAddress: string;
};

export type Settings = {
  addToCalendar: {
    addressFormat: string;
  };
};

export type Translations = {
  addToCalendar: {
    buttonText: string;
    eventTitle: string;
    eventLocation: string;
    scheduledClass: {
      eventDescription: string;
    };
    gymAccessSlot: {
      eventDescription: string;
    };
  };
};

const CustomDropdown = styled(Dropdown)`
  & [role="menu"] {
    top: 100%;
    right: 0;
  }
`;

const DropdownTrigger = styled(Dropdown.Trigger)`
  font-size: ${props => props.theme.fontSizes.small};
  line-height: 1.5;
  padding-left: ${props => props.theme.spacing.quarterSpacing};
  padding-right: ${props => props.theme.spacing.quarterSpacing};
  min-width: 12rem;

  &:not(:disabled):focus,
  &:not(:disabled):active {
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

const ListItem = styled.li`
  padding: 0;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export type AddToCalendarProps = Pick<ScheduledClass, "startDateTime" | "duration" | "studio" | "instructorName"> & {
  timetableType: TimetableType;
  gym: Gym;
  className: ScheduledClass["name"];
};

type Address = Gym["address"];

const getAddressFormat = () => {
  const { getSettings } = useLocale();
  const { addToCalendar: settings } = getSettings<Settings>();
  return settings.addressFormat;
};

const formatAddress = (address: Address): string => {
  const addressFormat = getAddressFormat();

  const addressLineOrder = (addressFormat || config.addressFormat).split(",");

  const addressParts = Object.keys(address)
    .filter(addressPart => addressLineOrder.includes(addressPart))
    .sort((part1, part2) => addressLineOrder.indexOf(part1) - addressLineOrder.indexOf(part2))
    .map(addressPart => address[addressPart as keyof Address] || "")
    .filter(Boolean);

  const formattedAddress = addressParts.join(", ");

  return formattedAddress;
};

export const AddToCalendar: React.FC<AddToCalendarProps> = ({
  timetableType,
  startDateTime,
  duration,
  studio,
  instructorName,
  className,
  gym,
}: AddToCalendarProps) => {
  const { getLabels, locale } = useLocale();
  const { addToCalendar: labels } = getLabels<Translations>();

  const { brandDisplayName, memberServicesEmailAddress } = useConfig<Config>();

  const titleFormat = labels.eventTitle || config.titleFormat;
  const locationFormat = labels.eventLocation || config.locationFormat;
  const descriptionFormat = labels[timetableType].eventDescription;

  const tokens: Record<string, string | number> = {
    brandName: brandDisplayName,
    gymName: gym.name,
    gymAddress: formatAddress(gym.address),
    className,
    instructor: instructorName,
    studio: studio || "",
    duration,
  };

  const event: CalendarEvent = {
    startDateTime,
    endDateTime: startDateTime.add(duration, "minute"),
    title: replaceTokens(titleFormat, tokens),
    location: replaceTokens(locationFormat, tokens),
    description: replaceTokens(descriptionFormat, tokens),
    organizer: brandDisplayName,
    organizerEmail: memberServicesEmailAddress,
    language: locale,
  };

  const calendarLinks = buildCalendarUrls(event);

  const renderCalendarLinks = () =>
    calendarLinks.map(({ text, url }) => (
      <ListItem key={text}>
        <Link href={url} target="_blank" rel="noreferrer">
          {text}
        </Link>
      </ListItem>
    ));

  return (
    <CustomDropdown>
      <DropdownTrigger variant="link">{labels.buttonText}</DropdownTrigger>
      <Dropdown.Content placement="custom">
        <List variant="plain">{renderCalendarLinks()}</List>
      </Dropdown.Content>
    </CustomDropdown>
  );
};
