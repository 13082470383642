export enum GenderRestriction {
  NoRestriction = "NoRestriction",
  FemaleOnly = "FemaleOnly",
  MaleOnly = "MaleOnly",
}

export interface Gym {
  id: number;
  name: string;
  address: {
    address1: string;
    address2?: string | null;
    address3?: string | null;
    town: string;
    postcode: string;
  };
  urlName: string;
  genderRestriction?: GenderRestriction;
}
