import React, { useContext } from "react";
import { Dayjs } from "dayjs";
import styled, { css, ThemeContext } from "styled-components";
import { MaxWidthContainer } from "../MaxWidthContainer";
import { CalendarDay } from "../CalendarDay/CalendarDay";
import { BaseCard } from "../CalendarCard/CalendarCard";
import { Spinner } from "@puregym/ui";

export type CalendarMode = "ListView" | "WeekView";

export interface CalendarActivities<T> {
  date: Dayjs;
  activities: T[];
}

export type IsoDateString = string;

interface ICalendarLabels {
  noBookableActivitiesOnThisDay: string;
  loading: string;
}

export interface CalendarProps<T> {
  mode: CalendarMode;
  activities: Map<IsoDateString, CalendarActivities<T>>;
  activityDates: Dayjs[];
  renderActivity: (activity: T) => JSX.Element;
  renderCalendarDayListHeader: () => JSX.Element | JSX.Element[] | null;
  labels: ICalendarLabels;
  isInitialized: boolean;
}

type CalendarContainerProps = {
  isWeekView: boolean;
};

const CalendarContainer = styled(MaxWidthContainer)<CalendarContainerProps>`
  padding-left: 0;
  padding-right: 0;

  ${({ isWeekView }: CalendarContainerProps) =>
    isWeekView &&
    css`
      display: grid;
      grid-template-columns: auto;
      column-gap: 4px;

      ${({ theme }) => theme.mediaQueries.xl} {
        column-gap: 10px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        grid-template-columns: repeat(8, minmax(0, 1fr));
      }
    `}
`;

const NoActivitiesOnThisDayCard = styled(BaseCard)`
  justify-content: flex-start;
  line-height: normal;
  color: ${({ theme }) => theme.colors.textMuted};
  padding: 10px 15px;

  ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: center;
    padding: 10px;
  }
`;

const InitLoadingContainer = styled(NoActivitiesOnThisDayCard)`
  margin-inline-start: ${({ theme }) => theme.spacing.baseSpacing};
  justify-content: center;
`;

type CalendarComponent<T = any> = React.FC<CalendarProps<T>>;

export const Calendar: CalendarComponent = <T,>({
  activities,
  activityDates: calendarDays,
  mode,
  renderActivity,
  renderCalendarDayListHeader,
  labels,
  isInitialized,
}: CalendarProps<T>) => {
  const theme = useContext(ThemeContext);

  const isWeekView = mode === "WeekView";
  if (!calendarDays.length) {
    return null;
  }

  const renderNoActivitiesCard = () => (
    <NoActivitiesOnThisDayCard isWeekView={isWeekView} isListView={!isWeekView}>
      {labels.noBookableActivitiesOnThisDay}
    </NoActivitiesOnThisDayCard>
  );

  const initializationSpinner = () => (
    <InitLoadingContainer isWeekView={isWeekView} isListView={!isWeekView}>
      <Spinner
        label={labels.loading}
        color={theme.colors.accents.light}
        emptyColor={theme.colors.primary.dark}
        thickness="6px"
        size="large"
        speed=".8s"
        data-testid="initSpinner"
      />
    </InitLoadingContainer>
  );

  const renderActivities = (day: Dayjs) => {
    const children = activities.get(day.toIsoDateString());

    if (!isInitialized) {
      return initializationSpinner();
    }
    if (!children?.activities.length) {
      return renderNoActivitiesCard();
    }

    return children.activities.map(renderActivity);
  };

  return (
    <CalendarContainer isWeekView={isWeekView} role="grid" data-testid="calendar">
      {calendarDays.map(day => (
        <CalendarDay
          key={day.unix()}
          date={day}
          isListView={!isWeekView}
          renderCalendarDayListHeader={renderCalendarDayListHeader}
        >
          {renderActivities(day)}
        </CalendarDay>
      ))}
    </CalendarContainer>
  );
};
