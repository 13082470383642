import { AxiosRequestConfig } from "axios";

export const replaceUrlTokensWithQueryParams = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { url = "", params = {} } = config;

  const asUrlParam = (key: string): string => `:${key.toLowerCase()}`;
  const getParamRegExp = (key: string): RegExp => new RegExp(asUrlParam(key), "i");

  const paramsToReplace = Object.keys(params).filter(key => getParamRegExp(key).test(url));

  if (!paramsToReplace.length) {
    return config;
  }

  const newUrl = paramsToReplace.reduce((u, key) => u.replace(getParamRegExp(key), config.params[key]), url);

  const newParams = Object.fromEntries(Object.entries(config.params).filter(([key]) => !paramsToReplace.includes(key)));

  return {
    ...config,
    url: newUrl,
    params: newParams,
  };
};
